import { memo } from 'react';
import { cx } from '@/utils';
import styles from './style/Table.Empty.module.css';

type Props = {
  Icon?:    React.ComponentType;
  message?: string;
  note?:    string;
};

export const TableEmpty = memo(({ Icon, ...props }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          {Icon &&
            <div className={cx(styles.row, styles.icon)}>
              <Icon />
            </div>}

          {props.message &&
            <div className={cx(styles.row, styles.message)}>
              {props.message}
            </div>}

          {props.note &&
            <div className={cx(styles.row, styles.note)}>
              {props.note}
            </div>}

        </div>
      </div>
    </div>
  );
});

TableEmpty.displayName = 'Table.Empty';