import { useCallback } from 'react';
import { Check, X } from 'react-feather';
import { ProgressRing } from '@/components/presentation/ProgressRing';
import { Tooltip } from '@/components/presentation/Tooltip';
import { useAbortUpload, useFileUploadProgress } from '@/containers/WorkspaceFileUpload/hooks';
import { FileUploadStatus } from '@/enums';
import type { WorkspaceFileUpload  } from '@/types';
import { useLock } from '@/utils/hooks';
import FileUploadBase from './FileUploadBase';
import styles from './style/FileUpload.module.css';

type FileUploadProps = {
  fileUpload: WorkspaceFileUpload;
};

export const FileUpload = ({ fileUpload }: FileUploadProps) => {

  const getAbort = useAbortUpload();
  const abort = useCallback(() => getAbort(fileUpload.identifier), [getAbort, fileUpload.identifier]);
  const progress = useFileUploadProgress(fileUpload.identifier);
  const [_, lock] = useLock();

  const Icon = useCallback(() => {
    if ([FileUploadStatus.InProgress, FileUploadStatus.Enqueued].includes(fileUpload.status)) {
      return (
        <div>
          <ProgressRing
            className={styles.progress}
            progress={progress}
            size={24} />
          <div className={styles.abort}>
            <X className={styles.x} />
          </div>
        </div>
      );
    } else if (fileUpload.status === FileUploadStatus.Aborted) {
      return (
        <Tooltip title="Upload Cancelled" arrow={false}>
          <div className={styles.aborted}>
            <div>!</div>
          </div>
        </Tooltip>
      );
    } else if (fileUpload.status === FileUploadStatus.Failed) {
      return (
        <Tooltip title="Upload Failed" arrow={false}>
          <div className={styles.failed}>
            <div>!</div>
          </div>
        </Tooltip>
      );
    } else if (fileUpload.status === FileUploadStatus.Completed) {
      return (
        <div className={styles.complete}>
          <Check className={styles.check} />
        </div>
      );
    }
  }, [
    progress,
    fileUpload.status,
  ]);

  const onClick = useCallback(() => {
    if ([FileUploadStatus.InProgress, FileUploadStatus.Enqueued].includes(fileUpload.status)) {
      return abort();
    }
  }, [
    abort,
    fileUpload.status,
  ]);

  return (
    <FileUploadBase
      file={fileUpload.file}>
      <div className={styles.status} onClick={lock(onClick)}>
        <Icon />
      </div>
    </FileUploadBase>
  );
};

export default FileUpload;