import { useContext } from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import * as api from '@/api';
import GroupTag from '@/components/presentation/TruncatedTags';
import * as Tab from '@/components/presentation/Workspace.Tab.Pane';
import { ProjectDetailsContext } from '@/screens/Project.Main/Context';
import styles from './style/Project.Details.module.css';

type Props = unknown;

export const ProjectDetails = (props: Props) => {
  const ctx = useContext(ProjectDetailsContext);

  return (
    <Tab.Panes.Container className={styles.root}>
      <Tab.Pane.Pane>
        <Tab.Pane.Title>Details</Tab.Pane.Title>
        <Tab.Items.Container>

          <Tab.Item.Container>
            <Tab.Item.TextLabel>Name</Tab.Item.TextLabel>
            <Tab.Item.Value>{ctx.query.data.project.name}</Tab.Item.Value>
          </Tab.Item.Container>
          <Tab.Item.Container>
            <Tab.Item.TextLabel>Description</Tab.Item.TextLabel>
            <Tab.Item.Value>{ctx.query.data.project.description}</Tab.Item.Value>
          </Tab.Item.Container>

        </Tab.Items.Container>

      </Tab.Pane.Pane>

      <TagPane />

    </Tab.Panes.Container>
  );
};

ProjectDetails.displayName = 'Project.Details';

const TagPane = () => {
  const ctx = useContext(ProjectDetailsContext);

  const { isSuccess, refetch, data } = useQuery({
    queryKey: ['get:projects/tags', ctx.query.data.project.id],
    queryFn: async () => {
      // return api.projects.fetchProjectTags({
      //   projectId: ctx.query.data.project.id,
      // });
      return { tags: [] };
    },
    enabled: !!ctx.query.data.project.id,
  });

  if (!data?.tags?.length) {
    return (
      <Tab.Pane.Pane>
        <Tab.Pane.Title>Tags</Tab.Pane.Title>

        <Tags>
          <div style={{ color: `var(--gray) `}}>No tags</div>
        </Tags>
      </Tab.Pane.Pane>
    );
  }

  return (
    <Tab.Pane.Pane>
      <Tab.Pane.Title>Tags</Tab.Pane.Title>

      <Tags>
        {data.tags.map(tag => (
          <GroupTag key={tag.id}>
            {tag.name}
          </GroupTag>
        ))}
      </Tags>
    </Tab.Pane.Pane>
  );
};

const Tags = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 5,
});