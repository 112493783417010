/** @jsxImportSource @emotion/react */
import type { KeyboardEventHandler } from 'react';
import { useCallback, useContext, useMemo, useReducer, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMutation } from '@tanstack/react-query';
import * as $queryApi from '@/api/queries';
import * as $searchApi from '@/api/search';
import { CancelAction } from '@/components/presentation/Message.QueryHint';
import type { Chat } from '@/types/chat';
import { ChatActionType } from '@/types/chat.actions';
import type { KolSearchForm, KolSearchResult } from '@/types/kol';
import { ActiveChatSessionContext, SubmitActionContext } from './context';
import { KolSearchInputForm } from './KolSearch.Form';
import { KolResultsTable } from './KolSearch.Table';
import { BasicSystemUserMessage } from './Message.System.Basic';
import { MessageContainer } from './Message.System.Wrapper';

type Props = {
  queryId: number;
  queryIdentifier: string;
  value: Chat.QueryState.HcpSelection;
};

function buildInitialState(value: Chat.QueryState.HcpSelection): State {
  return {
    form: {
      kolId: null,
      name: value.name || '',
    },
    results: value.results,
    status: value.status,
  };
}

export const HcpSelectionMessage = (props: Props) => {

  const [state, dispatch] = useReducer(reducer, buildInitialState(props.value));
  const chat = useContext(ActiveChatSessionContext);
  const submitAction = useContext(SubmitActionContext);

  const inputFocused = useRef(false);

  const submitDisabled = false;

  const submitFormMutation = useMutation({
    mutationFn: () => {
      return $searchApi.searchKols({
        name: state.form.name,
      });
    },
    onMutate: () => {
      dispatch({
        type: 'search-submitted',
      });
    },
    onError: _ => {
      dispatch({
        type: 'search-errored',
      });
    },
    onSuccess: res => {
      dispatch({
        type: 'search-results-available',
        payload: {
          results: res.results,
        },
      });
    },
  });

  const cancelMutation = useMutation({
    mutationFn: () => {
      return $queryApi.cancelQueryHcpSelection({
        chatInstanceId: chat.id,
        queryIdentifier: props.queryIdentifier,
      });
    },
    onMutate: () => {
      dispatch({ type: 'search-cancelled' });
    },
  });

  const handleHcpSearchInitiated = useCallback(() => {
    return submitFormMutation.mutateAsync();
  }, [submitFormMutation]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(e => {
    console.log('handleKeyDown', e.key);
    if (!inputFocused.current) return;

    if (submitDisabled) return;

    if (e.key === 'Enter') {
      e.preventDefault();
      handleHcpSearchInitiated();
    }
  }, [submitDisabled, handleHcpSearchInitiated]);

  const handleInputBlur = useCallback(() => {
    inputFocused.current = false;
  }, []);

  const handleInputFocus = useCallback(() => {
    inputFocused.current = true;
  }, []);

  const setFormState = useCallback((form: KolSearchForm) => {
    dispatch({ type: 'form-updated', payload: form });
  }, []);

  const handleHcpSelected = useCallback((item: KolSearchResult) => {
    const query = `${props.value.originalQuery}\n\nUse the following HCP: ${item.name}`;
    dispatch({
      type: 'hcp-selected',
    });
    return submitAction({
      type: ChatActionType.ReaskWithHcp,
      payload: {
        hcpId: item.id,
        queryId: props.queryId,
      },
    }, query);
  }, [props.queryId, props.value.originalQuery, submitAction]);

  const handleSearchCancelled = useCallback(() => {
    return cancelMutation.mutateAsync();
  }, [cancelMutation]);

  const handleResetForm = useCallback(() => {
    dispatch({ type: 'form-reset' });
  }, []);

  const isInteractive = useMemo(() => {
    return !['form-submitted', 'form-cancelled', 'form-results', 'hcp-selected'].includes(state.status);
  }, [state.status]);
  const showResults = useMemo(() => {
    return ['form-results'].includes(state.status) && state.results.length > 0;
  }, [state.status, state.results]);
  const showNoResultsMessage = useMemo(() => {
    return ['form-results'].includes(state.status) && state.results.length === 0;
  }, [state.status, state.results]);
  const showLoading = useMemo(() => {
    return ['form-submitted'].includes(state.status);
  }, [state.status]);
  const showCancelButton = useMemo(() => {
    return !['form-cancelled', 'hcp-selected'].includes(state.status);
  }, [state.status]);
  const showStartNewSearch = useMemo(() => {
    return ['form-results'].includes(state.status);
  }, [state.status]);
  const showInputForm = useMemo(() => {
    return ['form-entry', 'form-error'].includes(state.status);
  }, [state.status]);

  const systemCss = useMemo(() => {
    if (state.status !== 'form-results') return undefined;

    return css({
      maxWidth: '100%',
    });
  }, [state.status]);

  const message = `To answer your question, I need help from you to find the right HCP. Please use the search tool below to find the HCP your question is about.`;

  return (
    <MessageContainer>
      <BasicSystemUserMessage bodyStyles={systemCss}>
        <Message>{message}</Message>
        {showInputForm && (
          <KolSearchInputForm
            formState={state.form}
            inputDisabled={!isInteractive}
            onKeyDown={handleKeyDown}
            onInputFocus={handleInputFocus}
            onInputBlur={handleInputBlur}
            onKolSelect={handleHcpSelected}
            setFormState={setFormState} />
        )}
        {showLoading && (
          <div>
            Loading...
          </div>
        )}
        {showResults && (
          <ResultsWrapper>
            Here are the results for {state.form.name}:<br />
            <StyledResultsTable
              records={state.results}
              onSelectProfile={handleHcpSelected}
            />
          </ResultsWrapper>
        )}
        {showNoResultsMessage && (
          <NoResults>
            No results found for {state.form.name}
          </NoResults>
        )}
        <Divider />
        {showStartNewSearch && (
          <StyledCancelButton
            displayValue="Start a new search"
            onClick={handleResetForm} />
        )}
        {showCancelButton && (
          <StyledCancelButton
            displayValue="Ask another question"
            onClick={handleSearchCancelled} />
        )}
      </BasicSystemUserMessage>
    </MessageContainer>
  );
};

const Message = styled.div({
  marginBottom: 15,
});

const StyledCancelButton = styled(CancelAction)({
  marginTop: 15,
});

const NoResults = styled.div({
  marginTop: 15,
});

const ResultsWrapper = styled.div({
  marginTop: 15,
});
const StyledResultsTable = styled(KolResultsTable)({
  marginTop: 10,
});

const Divider = styled.div(({ theme }) => ({
  width: '100%',
  height: 2,
  backgroundColor: theme.palette.gray.light2,
  margin: '15px 0',
}));

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'name-changed':
      return {
        ...state,
        form: {
          ...state.form,
          name: action.payload,
        },
      };
    case 'form-updated':
      return {
        ...state,
        form: action.payload,
      };
    case 'results-changed':
      return {
        ...state,
        results: action.payload,
      };
    case 'form-reset':
      return {
        form: {
          kolId: null,
          name: '',
        },
        results: [],
        status: 'form-entry',
      };
    case 'search-errored':
      return {
        ...state,
        status: 'form-error',
      };
    case 'search-submitted':
      return {
        ...state,
        status: 'form-submitted',
      };
    case 'hcp-selected': {
      return {
        ...state,
        status: 'hcp-selected',
      };
    };
    case 'search-cancelled': {
      return {
        ...state,
        status: 'form-cancelled',
      };
    }
    default:
      return state;
  }
};

type NameChangedAction = {
  type: 'name-changed';
  payload: string;
};
type ResultsChangedAction = {
  type: 'results-changed';
  payload: KolSearchResult[];
};
type FormUpdatedAction = {
  type: 'form-updated';
  payload: KolSearchForm;
};
type FormReset = {
  type: 'form-reset';
};
type SearchErrored = {
  type: 'search-errored';
};
type SearchSubmitted = {
  type: 'search-submitted';
};
type ResultsAvailable = {
  type: 'search-results-available';
  payload: {
    results: KolSearchResult[];
  };
};
type HcpSelected = {
  type: 'hcp-selected';
};
type SearchCancelled = {
  type: 'search-cancelled';
};
type Action =
  NameChangedAction |
  ResultsChangedAction |
  FormUpdatedAction |
  FormReset |
  SearchErrored |
  SearchSubmitted |
  ResultsAvailable |
  HcpSelected |
  SearchCancelled
  ;

type State = {
  form: KolSearchForm;
  results: KolSearchResult[];
  status: Chat.QueryState.HcpSelection['status'];
};