import { useCallback } from 'react';
import type { CategoryTheme } from '@/types/branding';
import type { SetColorCategoryTheme} from './branding.theming';
import { useSetColorCategoryThemeMutation } from './branding.theming';
import { useQueryDataAccessor } from './useQueryDataAccessor';

export const useSetColorCategoryMutation = <Context extends Mutation.Context>(options: Mutation.Options<Context>  = {}) => {
  const helpers = useSetColorCategoryThemeHelpers();

  const mutation = useSetColorCategoryThemeMutation<Context>({
    ...options,
    onError: (...args) => {
      helpers.onError(...args);
      options.onError?.(...args);
    },
    onMutate: variables => {
      const ctx = helpers.onMutate(variables);

      options.onMutate?.(variables);

      return ctx;
    },
  });

  return mutation;
};

const useSetColorCategoryThemeHelpers = <O extends Helpers<Mutation.Context>>() => {
  const accessor = useQueryDataAccessor();

  const onMutate = useCallback((variables: SetColorCategoryTheme.Variables) => {
    accessor.setQueryData(data => {
      return {
        ...data,
        theming: {
          palettes: data.theming.palettes,
          themes: data.theming.themes.map(x => {
            return x.categoryId === variables.categoryId
              ? { ...x, paletteId: variables.paletteId }
              : x;
          }),
        },
      };
    });

    const data = accessor.getQueryData();
    const { themes } = data.theming;

    return { themes };
  }, [accessor]);

  const onError: O['onError'] = useCallback((e, variables, ctx) => {

    if (!ctx?.themes) return;

    accessor.setQueryData(data => {
      return {
        ...data,
        theming: {
          palettes: data.theming.palettes,
          themes: ctx.themes,
        },
      };
    });

  }, [accessor]);

  return {
    onError,
    onMutate,
  };
};

declare namespace Mutation {
  export type Options<C = unknown> = SetColorCategoryTheme.Options<C>;

  export type Context = {
    themes: CategoryTheme[];
  };

}

type Helpers<C extends Mutation.Context, T extends SetColorCategoryTheme.Options<C> = SetColorCategoryTheme.Options<C>> = {
  onError:  (...args: Parameters<T['onError']>) => unknown;
  onMutate: (variables: SetColorCategoryTheme.Variables) => C;
};