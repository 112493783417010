import { useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { Info } from 'react-feather';
import { LinkContext } from '@/components/BrandInsights/context';
import { Constants } from '@/components/Theme';
import { ChatContextType } from '@/enums';
import { useModal } from '@/hooks/useModal';
import { useAppSelector } from '@/store';
import { buildMessageItems, buildMessageQueries } from '@/utils/queries';
import {
  ChatSessionSelectedQueryContext,
  ChatSessionFileUploadsContext,
  ChatSessionQueriesContext,
  MessagesListRefContext,
  ChatHasAccessContext,
  ActiveChatSessionContext,
  ChatSessionKolSearchesContext,
} from './context';
import { ChatGuidelinesModal } from './GuidelinesModal';
import { useChatPermissions, useChatGuidelinesCookie } from './hooks';
import { UnsubmittedMessage } from './Input';
import { KolContextGetStarted } from './Kol.GetStarted';
import { messageLaneRightPadding, messageLaneLeftPadding, messageLaneWidth } from './Message.styles';
import { InitialSystemMessage } from './Message.System.Initial';
import { MessageList } from './MessageList';
import { NoAccessBanner } from './NoAccessBanner';

export const ChatInstance = () => {
  const selected = useContext(ChatSessionSelectedQueryContext);
  const queriesMap = useContext(ChatSessionQueriesContext);
  const fileUploads = useContext(ChatSessionFileUploadsContext);
  const kolSearches = useContext(ChatSessionKolSearchesContext);
  const hasAccess = useContext(ChatHasAccessContext);
  const link = useContext(LinkContext);
  const chat = useContext(ActiveChatSessionContext);
  const chatPermissions = useChatPermissions();

  const [_, setMessagesRef] = useContext(MessagesListRefContext);
  const isFirstSession = useAppSelector(state => state.user.isFirstSession);
  const { isDismissed: chatGuidelinesDismissed } = useChatGuidelinesCookie();
  const [toggleGuidelinesModal, GuidelinesModal] = useModal(ChatGuidelinesModal, { defaultOpen: isFirstSession && !chatGuidelinesDismissed });
  const chatGuidelines = useAppSelector(state => state.group.features.chatGuidelines);
  const showChatGuidelines = !!chatGuidelines;

  const queries = useMemo(() => buildMessageQueries({
    selectedQueryIdentifier: selected?.identifier,
    queries: queriesMap,
  }), [selected?.identifier, queriesMap]);

  const items = useMemo(() => buildMessageItems({
    fileUploads,
    queries,
    kolSearches,
  }), [fileUploads, kolSearches, queries]);

  const requiresKolSearch = useMemo(() => {
    return chat.context.type === ChatContextType.KolProfile && !chat.context.data.id;
  }, [chat.context]);

  const renderMessageList = useCallback(() => {
    if (!link.initialized) return null;

    if (chat.context.type === ChatContextType.KolProfile &&
      !chat.context.data.id &&
      !items.length) {
      return <KolContextGetStarted />;
    }

    if (!items.length) {
      return <InitialSystemMessage />;
    }

    return (
      <MessageList
        items={items} />
    );

  }, [chat.context, items, link.initialized]);

  return (
    <Root>
      {!hasAccess && <NoAccessBanner />}

      <Messages>
        <MessagesWrap ref={setMessagesRef}>
          {renderMessageList()}
        </MessagesWrap>
      </Messages>

      <Footer>
        {chatPermissions.canModify && <UnsubmittedMessage inputDisabled={requiresKolSearch} />}
        {showChatGuidelines && (
          <DisclaimerText>
            Shared content is meant for internal use only and not meant to be shared externally.
            <OpenDisclaimer onClick={toggleGuidelinesModal}>View Usage Guidelines <Info size={16} /></OpenDisclaimer>
          </DisclaimerText>
        )}
      </Footer>

      {showChatGuidelines && <GuidelinesModal guidelines={chatGuidelines} />}
    </Root>
  );
};

const MaxContainerWidth = 1100;

const DisclaimerText = styled.div({
  width: messageLaneWidth,
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
});

const OpenDisclaimer = styled.div(({ theme }) => ({
  display: 'inline-flex',

  color: theme.palette.sentiment.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
  marginLeft: 10,
  alignItems: 'center',
  gap: 4,
}));

const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',

  height: `calc(100% - ${Constants.Modal.HeaderHeight}px)`,
  width: '100%',
  margin: '0 auto',
  boxSizing: 'border-box',
});

const Messages = styled.div(({
  boxSizing: 'border-box',
  height: '100%',
  overflowY: 'auto',

  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
  backgroundAttachment: 'local, local, scroll, scroll',
}));

const MessagesWrap = styled.div(({
  height: '100%',
  width: '100%',
  maxWidth: MaxContainerWidth,
  margin: '0 auto',

  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  paddingTop: 15,
  paddingRight: 10,
  paddingLeft: 10,
}));

const Footer = styled.div(({
  padding: `20px ${messageLaneRightPadding}px 15px ${messageLaneLeftPadding}px`,
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: MaxContainerWidth,
  margin: '0 auto',
}));