import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import { format } from 'date-fns/fp';
import { cx } from '@/utils';
import type { CalendarPickerHeaderParams } from './interfaces';
import styles from './style.module.css';

type Props = {
  className?: string;
} & CalendarPickerHeaderParams;

const CalendarPickerHeader = (params: Props) => {
  return (
    <div className={cx(styles.header, params.className)}>
      <div className={styles.month}>{fMonth(params.date)}</div>
      <div className={styles.btns}>
        <button
          className={styles.btn}
          onClick={() => params.decreaseMonth()}>
          <ChevronLeftIcon className={styles.chevron} />
        </button>
        <button
          className={styles.btn}
          onClick={() => params.increaseMonth()}>
          <ChevronRightIcon className={styles.chevron} />
        </button>
      </div>
    </div>
  );
};

const fMonth = format('MMMM uu');

export { CalendarPickerHeader };
export default CalendarPickerHeader;