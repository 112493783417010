import type { ChangeEvent, KeyboardEventHandler } from 'react';
import { useCallback, useContext, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';
import { SoundInput } from '@/components/icons/SoundInput';
import { Tooltip } from '@/components/presentation';
import { useTheme } from '@/components/Theme';
import { useHasBetaPermission, useHasChatVoiceInputEnabled } from '@/store/selectors';
import { capitalize } from '@/utils';
import { pulse } from '@/utils/color';
import { shouldForwardProp } from '@/utils/emotion';
import { SubmitMessageContext, ChatSessionInputContext } from './context';
import type { MicState } from './hooks/useSpeechRecognition';
import { useSpeechRecognition } from './hooks/useSpeechRecognition';
import { UploadFilesButton, uploadButtonHeight } from './Input.Upload';
import { messageLaneWidth } from './Message.styles';
import { SendIcon } from './SendIcon';

type Props = {
  className?: string;
  inputDisabled?: boolean;
};

export const UnsubmittedMessage = ({ className, inputDisabled }: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement>();
  const soundLevelRef = useRef<HTMLDivElement>();
  const input = useContext(ChatSessionInputContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const theme = useTheme();

  const { canSubmit, onSubmit: handleSubmit } = useContext(SubmitMessageContext);
  const hasBetaPermission = useHasBetaPermission();
  const hasChatVoiceInputEnabled = useHasChatVoiceInputEnabled();

  // const uploadFiles = useContext(UploadFilesContext);
  // const isPreparingUpload = useContext(IsPreparingUploadContext);

  const disabled = useMemo(() => {
    return !canSubmit || inputDisabled;
  }, [
    canSubmit,
    inputDisabled,
  ]);

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = useCallback(e => {

    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.shiftKey) {
        dispatch({
          type: 'input-changed',
          payload: {
            value: `${input}\n`,
          },
        });
      } else if (!disabled) {
        handleSubmit();
      }
    }
  }, [disabled, dispatch, handleSubmit, input]);

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch({
      type: 'input-changed',
      payload: { value: e.target.value },
    });
  }, [dispatch]);

  const onSpeechFinished = useCallback((value: string, prefix: string) => {
    let newVal = prefix + ' ' + capitalize(value);

    if (!endsWithPunctuation(newVal)) {
      newVal = newVal + '?';
    }

    dispatch({
      type: 'input-changed',
      payload: { value: newVal },
    });
  }, [dispatch]);

  const onAudioLevelChange = useCallback((level: number) => {
    if (!soundLevelRef.current) {
      console.log('no ref');
    }
    soundLevelRef.current?.setAttribute('style', `height: ${Math.min(20, Math.max(level, 4))}px`);
  }, []);

  const onInterimResult = useCallback((result: string, prefix: string) => {
    const value = prefix + ' ' + capitalize(result);
    dispatch({
      type: 'input-changed',
      payload: { value },
    });
  }, [dispatch]);

  const { start, stop, listening, isSupported: speechRecogSupported, micState, errorMsg } = useSpeechRecognition({
    onResult: onSpeechFinished,
    onInterimResult,
    onAudioLevelChange,
  });

  const onVoiceClick = useCallback(() => {
    if (listening) {
      stop();
    } else {
      start(input);
      textAreaRef.current.addEventListener('focus', () => {
        stop();
      });
    }
  }, [input, listening, start, stop]);

  const handleSubmitButtonClick = useCallback(() => {
    if (listening) {
      stop();
    }

    handleSubmit();
  }, [handleSubmit, listening, stop]);

  const MicButtonIcon = useCallback(() => {
    if (micState === 'listening' || micState === 'waiting-for-permission') {
      return <SoundInput
        ref={soundLevelRef}
        color={theme.palette.white.main}
        paused={micState === 'waiting-for-permission'} />;
    } else if (micState === 'error') {
      return <StyledMicOffIcon />;
    } else {
      return <StyledMicIcon />;
    }
  }, [micState, theme.palette.white.main]);

  return (
    <Root className={className}>
      <Wrap>
        <TextArea
          ref={textAreaRef}
          disabled={inputDisabled}
          placeholder={listening ? 'Speak your question.' : 'Type your question.'}
          minRows={3}
          maxRows={15}
          value={input}
          onKeyDown={handleKeyDown}
          onChange={handleChange} />

        <Buttons>
          {speechRecogSupported && hasBetaPermission && hasChatVoiceInputEnabled && (
            <Tooltip title={micState === 'error' ? errorMsg : null} placement='top'>
              <StyledMicButton
                disabled={inputDisabled || micState === 'error'}
                micState={micState}
                onClick={onVoiceClick}>
                <MicButtonIcon />
              </StyledMicButton>
            </Tooltip>
          )}

          <Button
            disabled={disabled}
            onClick={handleSubmitButtonClick}>
            <StyledSendIcon
              disabled={disabled}
              size={40} />
          </Button>
        </Buttons>

        {/* <StyledUploadButton
          isLoading={isPreparingUpload}
          onSelect={uploadFiles} /> */}
      </Wrap>
    </Root>
  );
};

const baseTextareaPadding = 10;

const submitBtnSize = 38;
const buttonsSpacing = 10;
const buttonsCount = 2;

const Root = styled.div(() => ({
  width: messageLaneWidth,
  margin: '0 auto',
}));

const Wrap = styled.div({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
});

const TextArea = styled(TextareaAutosize)(({ theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: theme.palette.white.main,
  borderRadius: 5,
  border: `2px solid ${theme.palette.gray.light2}`,
  outline: 'none',
  resize: 'none',
  padding: baseTextareaPadding,
  paddingRight: baseTextareaPadding + (submitBtnSize * buttonsCount) + (buttonsSpacing * (buttonsCount - 1)),
  paddingBottom: baseTextareaPadding + uploadButtonHeight,
  width: '100%',

  fontFamily: theme.fonts.regular,
  fontSize: 16,
  color: theme.palette.black.light1,
  transition: 'border 0.3s',

  '&:hover': {
    border: `2px solid ${theme.palette.primary.main}`,
  },

  '&:focus-visible': {
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'not-allowed',
  },
}));

const Buttons = styled.div({
  display: 'flex',
  gap: buttonsSpacing,
  position: 'absolute',
  right: 10,
});

const Button = styled.button`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  width: ${submitBtnSize}px;
  height: ${submitBtnSize}px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.3s;

  background-color: ${props => props.disabled ? props.theme.palette.gray.light1 : props.theme.palette.sentiment.primary.main};

  &:hover:not([disabled]) {
    background-color: ${({ theme }) => theme.palette.sentiment.primary.dark};
  }
`;

type SendProps = {
  disabled: boolean;
};

const StyledSendIcon = styled(SendIcon, { shouldForwardProp: shouldForwardProp('disabled') }) <SendProps>`
  padding-left: 4px;
  fill: ${props => props.disabled ? props.theme.palette.gray.dark1 : props.theme.palette.white.main};

  transition: fill 0.3s;
`;

const StyledMicIcon = styled(MicIcon, {})`
  color: var(--black-l);
`;

const StyledMicOffIcon = styled(MicOffIcon)`
  color: var(--black-l);
`;

type StyledMicButtonProps = {
  micState: MicState;
};

const StyledMicButton = styled('button')<StyledMicButtonProps>(({ theme, micState, disabled }) => ({
  cursor: disabled ? 'not-allowed' : 'pointer',

  width: `${submitBtnSize}px`,
  height: `${submitBtnSize}px`,
  borderRadius: '50%',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  transition: 'background-color 0.3s',

  boxShadow: `0 0 0 0 ${theme.palette.sentiment.primary.main}`,
  animation: micState === 'listening' ? `${pulse(theme.palette.sentiment.primary.main)} 4s infinite` : 'none',
  backgroundColor: micState === 'listening' || micState === 'waiting-for-permission' ? theme.palette.sentiment.primary.main : 'none',

  '&:hover:not([disabled])': {
    backgroundColor: micState === 'listening' || micState === 'waiting-for-permission' ? undefined : disabled ? theme.palette.gray.light1 : theme.palette.sentiment.primary.light,
  },

  '&:hover:not([disabled]) svg': {
    color: theme.palette.sentiment.primary.dark,
    //backgroundColor: listening ? undefined : disabled ? theme.palette.gray.light1 : theme.palette.sentiment.primary.light,
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledUploadButton = styled(UploadFilesButton)({
  position: 'absolute',
  bottom: baseTextareaPadding,
  left: baseTextareaPadding,
});


function endsWithPunctuation(str: string) {
  const punctuation = /[.,!?;:'"-]/;
  return punctuation.test(str.charAt(str.length - 1));
}