import { Trash } from 'react-feather';
import { ColorInput } from '@/components/ColorInput';
import styles from './style/ColorPalette.module.css';

type Props = {
  readonly?:     boolean;
  index:         number;
  onChangeColor: (val: string, index: number) => void;
  onRemoveColor: (index: number) => void;
  value:         string;
};

export const Color = (props: Props) => {
  return (
    <div className={styles.item}>
      <ColorInput
        initialValue={props.value}
        onChangeColor={val => props.onChangeColor(val, props.index)}
        tabIndex={props.index} />
      {!props.readonly &&
        <div className={styles.remove}>
          <button onClick={() => props.onRemoveColor(props.index)}>
            <Trash />
          </button>
        </div>}
    </div>
  );
};

Color.displayName = 'ColorPalette.Color';