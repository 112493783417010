import { useCallback, useMemo } from 'react';
import { useMatch, useParams, useLocation, Navigate, Route, Routes, generatePath } from 'react-router-dom';
import { TabView } from '@/components/TabView';
import { useTabViewState } from '@/components/TabView/hooks/useTabViewState';
import { type RouteParams, RouteKey, type SceneMap } from './interfaces';

type Props = {
  map: SceneMap;
  path?: string;
};

export const ProjectTabView = ({
  map,
  path = `/projects/:projectId/:tab`,
}: Props) => {
  const params = useParams<RouteParams>();
  const location = useLocation();

  const keys = useMemo(() => Object.keys(map) as RouteKey[], [map]);
  const routes = useMemo(() => keys.map(k => ({ key: k, ...map[k] })), [keys, map]);

  const formatPath = useCallback((tab: RouteKey) => generatePath(path, { ...params, tab }), [path, params]);

  const match = useMatch(path);

  const [{ index }, updateLocation] = useTabViewState<RouteKey>({
    tabParam: match?.params?.tab || null,
    formatPath,
    routes: keys,
  });

  const renderTabView = useCallback(() => {
    const defaultRoute = formatPath(RouteKey.Details);

    return (
      <Routes>
        {keys.map((key: RouteKey) => {
          const Component = map[key].component;

          return (
            <Route
              key={key}
              path={map[key].path}
              element={<Component />} />
          );
        })};
        <Route
          path="*"
          element={<Navigate to={`${defaultRoute}${location.search}${location.hash}`} replace />} />
      </Routes>
    );
  }, [
    location.search,
    location.hash,
    formatPath,
    keys,
    map,
  ]);

  return (
    <TabView
      index={index}
      onIndexChange={updateLocation}
      routes={routes}
      renderTabView={renderTabView} />
  );
};