/** @jsxImportSource @emotion/react */
import { memo, useCallback, useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import isMobile from 'ismobilejs';
import { SideBarExpandedContext } from '@/components/BrandInsights/context';
import { MessageRegenerateResponseFooter } from '@/components/presentation';
import type { Chat } from '@/types';
import { RegenerateMessageContext } from './context';
import { useIsFullPageChat } from './hooks';
import { SystemAuthor } from './Message.Author';
import { MessageResponse } from './Message.Response';
import { extendedMessageWidth, messageWidth, fullChatExtendedMessageWidth } from './Message.styles';
import { SystemUserMessageFooter, SystemUserMessageFooterContainer } from './Message.System.Footer';
import { useStyles } from './Message.System.styles';
import { ChatMessageVariantSelector } from './Message.VariantSelector';

type Props = {
  className?: string;
  item: Chat.Query;
  hasRegenerateFooter: boolean;
};

type BaseProps = {
  children: React.ReactNode;
  className?: string;
  extendedWidth?: boolean;
};

export const BaseSystemUserMessage = memo(({ children, className, extendedWidth }: BaseProps) => {
  const device = isMobile();
  const styles = useStyles();

  const maxWidth = useMemo(() => extendedWidth ? extendedMessageWidth : messageWidth, [extendedWidth]);

  return (
    <div css={styles.root} className={className}>
      {!device.phone && (
        <div css={styles.authorWrap}>
          <SystemAuthor css={styles.author} />
        </div>
      )}
      <div css={css({ maxWidth })}>
        <div css={css([styles.body, css({ maxWidth })])}>
          {children}
        </div>
      </div>
    </div>
  );
});

export const SystemUserMessage = ({ className, item, hasRegenerateFooter }: Props) => {
  const device = isMobile();
  const styles = useStyles();

  const [expanded] = useContext(SideBarExpandedContext);

  const numCitations = useMemo(() => {
    if (!item.citations) return 0;
    return item.citations.length;
  }, [item.citations]);

  const hasCitations = numCitations > 0;
  const isFullPageChat = useIsFullPageChat();

  const bodyWidth = useMemo(() => {
    if (isFullPageChat) return fullChatExtendedMessageWidth;

    const isLongResponse = item.response.value.length > 250;
    const hasFollowups = item.suggestedFollowups.length > 0;
    if ((isLongResponse || hasFollowups) && !expanded) return extendedMessageWidth;

    if (hasCitations) return messageWidth;

    return undefined;
  }, [isFullPageChat, item.response, item.suggestedFollowups.length, expanded, hasCitations]);

  return (
    <div css={styles.root} className={className}>
      {!device.phone && (
        <div css={styles.authorWrap}>
          <SystemAuthor css={styles.author} />
          <ChatMessageVariantSelector item={item} />
        </div>
      )}
      <div css={css({ maxWidth: bodyWidth })}>
        <div css={css([styles.body, css({ maxWidth: bodyWidth })])}>
          <MessageResponse
            queryIdentifier={item.identifier}
            item={item.response} />
          <SystemUserMessageFooterContainer>
            <SystemUserMessageFooter
              item={item}
              numCitations={numCitations} />
          </SystemUserMessageFooterContainer>

        </div>
        {hasRegenerateFooter && <RegenerateResponse item={item} />}
      </div>
    </div>
  );
};

type RegenerateResponseProps = {
  item: Chat.Query;
};

const RegenerateResponse = ({ item }: RegenerateResponseProps) => {
  const { onRegenerate } = useContext(RegenerateMessageContext);

  const handleClick = useCallback(() => {
    onRegenerate(item);
  }, [item, onRegenerate]);

  return (
    <MessageRegenerateResponseFooter
      css={css({ marginTop: 10 })}
      onClick={handleClick} />
  );
};