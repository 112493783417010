import type { WorkspaceObject } from '@/types';
import { safeJsonDate } from '@/utils/date';

export const normalizeWorkspaceObject = (data: WorkspaceObject<string>): WorkspaceObject => {
  if (!data) return null;

  return Object.assign(data, {
    createdOn: safeJsonDate(data.createdOn),
    expiresOn: safeJsonDate(data.expiresOn),
  });
};