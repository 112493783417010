import { ChatResponseType } from '@/enums';
import type { Chat } from '@/types';
import { markdownToRawText } from '@/utils/markdown';

export function getQueryResponseAsText(response: Chat.QueryResponse): string {
  switch (response.type) {
    case ChatResponseType.Text: return response.value;
    case ChatResponseType.Markdown: return markdownToRawText(response.value);
    case ChatResponseType.HcpSummary: return ''; // TODO: Implement
    case ChatResponseType.HcpSelection: return ''; // TODO: Implement

    default: {
      throw new UnreachableCaseError(response);
    }
  }
}