import type { ChatState, FiltersState } from './interfaces';
import { generateEmptyChatSessionFiltersState } from './state.initial-state';

export function filtersReducer({
  settings,
  session: state,
}: ChatState.State, action: ChatState.Action): FiltersState.State {

  switch (action.type) {
    case 'filters/reset': {
      const context = state.chatInstance.context;
      return generateEmptyChatSessionFiltersState({ context, features: settings });
    }
    case 'filters/updated': {
      return Object.fromEntries(Object.entries({ ...state.filters, ...action.payload.filters }).filter(([_, v]) => v != null));
    }
    case 'filters/add-custom-filter': {
      return { ...state.filters, custom: state.filters.custom.concat(action.payload.value) };
    }
    case 'filters/remove-custom-filter': {
      return { ...state.filters, custom: state.filters.custom.filter((_, i) => i !== action.payload.id) };
    }
    case 'filters/update-custom-filter': {
      return { ...state.filters, custom: state.filters.custom.map((f, i) => i === action.payload.id ? action.payload.value : f) };
    }

    case 'chat-loaded':
      return {
        ...generateEmptyChatSessionFiltersState({
          context: action.payload.chatInstance.context,
          features: settings,
        }),
        ...action.payload.chatInstance.filterOverrides,
      };

    case 'query-response-available':
      return {
        ...generateEmptyChatSessionFiltersState({
          context: state.chatInstance.context,
          features: settings,
        }),
        ...action.payload.chatInstance.filterOverrides,
      };

    default:
      return state.filters;
  }
}