import type { Chat, WorkspaceFile, WorkspaceFileUpload } from '@/types';
import { safeJsonDate } from './date';

export const normalizeFile = (data: WorkspaceFile<string>): WorkspaceFile => {
  if (!data) return null;

  return Object.assign(data, {
    createdOn: safeJsonDate(data.createdOn),
    lastDownloadedOn: safeJsonDate(data.lastDownloadedOn),
    lastModifiedOn: safeJsonDate(data.lastModifiedOn),
    publishedOn: safeJsonDate(data.publishedOn),
  });
};

export const normalizeFileUpload = (data: WorkspaceFileUpload<string>): WorkspaceFileUpload => {
  if (!data) return null;

  return Object.assign(data, {
    initiatedOn: safeJsonDate(data.initiatedOn),
    lastUpdatedOn: safeJsonDate(data.lastUpdatedOn),
  });
};

export function transformChatFileUpload(data: Chat.FileUpload<string>): Chat.FileUpload {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
}