import { memo } from 'react';
import { format } from 'date-fns';
import { Trash2 } from 'react-feather';
import styles from './style/EventPopover.module.css';

type Props = {
  timeStart: Date;
  timeEnd: Date;
  onDelete: () => unknown;
};

export const AvailabilityEventPopover = memo(({ timeStart, timeEnd, onDelete }: Props) => {

  return (
    <>
      <div className={styles.body}>
        <EventDate
          start={timeStart}
          end={timeEnd} />
      </div>
      <div className={styles.actions}>
        <Trash2
          className={styles.delete}
          onClick={onDelete}
          size={22} />
      </div>
    </>
  );
});

type DateProps = {
  start: Date;
  end: Date;
};

export const EventDate = memo(({ start, end }: DateProps) => {
  const day = format(start, 'EEEE MMM d');
  const startDisplay = format(start, 'h:mm a');
  const endDisplay = format(end, 'h:mm a');

  return (
    <>
      <div className={styles.date}>{day}</div>
      <div className={styles.time}>{`${startDisplay} - ${endDisplay}`}</div>
    </>
  );
});