import { useCallback, useMemo, useState } from 'react';
import type { ActionMeta } from 'react-select';
import Select from 'react-select';
import { useTheme } from '@/components/Theme';
import type { RecordFilter } from '@/types/chat.filters';
import { useFilterSearchQuery } from './hooks/useFilterSearch';
import * as Presentation from './Presentation';

type Props = {
  onChange?: (value: RecordFilter.Item) => unknown;
  readonly?: boolean;
  value: RecordFilter.Item | null;
};

export const Record = ({ onChange, readonly, value }: Props) => {

  const [input, setInput] = useState('');

  const searchEnabled = useMemo(() => {
    return !!input;
  }, [input]);

  const handleInputChange = useCallback((value: string) => {
    setInput(value);
  }, []);

  const query = useFilterSearchQuery({
    enabled: searchEnabled,
    excluded: [],
    type: 'hcps',
    value: input,
  });

  const loadingMessage = useCallback(() => {
    return 'Searching...';
  }, []);

  const noOptionsMessage = useCallback(() => {
    return searchEnabled ? 'No records found' : 'Start typing to search';
  }, [searchEnabled]);

  const handleChange = useCallback((value: RecordFilter.Item[], meta: ActionMeta<RecordFilter.Item>) => {
    return onChange(value[0]);
  }, [onChange]);

  const theme = useTheme();

  const selectStyles = useMemo(() => Presentation.buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => Presentation.buildSelectTheme(theme), [theme]);

  const isSearchable = useMemo(() => {
    return !readonly && !value;
  }, [readonly, value]);

  return (
    <Presentation.Row>
      <Presentation.Label>Record</Presentation.Label>
      <Presentation.Value>
        <Select<RecordFilter.Item, true>
          styles={selectStyles}
          components={{
            DropdownIndicator: null,
          }}
          menuIsOpen={!isSearchable ? false : undefined}
          isMulti={true}
          inputValue={input}
          onInputChange={handleInputChange}
          onChange={handleChange}
          theme={selectTheme}
          value={value}
          isDisabled={readonly}
          closeMenuOnSelect={false}
          isSearchable={isSearchable}
          isClearable={!readonly}
          options={query.data.results as RecordFilter.Item[]}
          isLoading={query.isLoading || query.isFetching}
          noOptionsMessage={noOptionsMessage}
          placeholder={`Search Hcps`}
          loadingMessage={loadingMessage}
          getOptionLabel={o => o.name}
          getOptionValue={o => o.id}
          menuPlacement="auto" />
      </Presentation.Value>
    </Presentation.Row>
  );
};