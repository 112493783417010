import { renderToStaticMarkup } from 'react-dom/server';
import { format, isToday } from 'date-fns';
import { cx } from '@/utils';
import styles from './style/Calendar.module.css';

type Props = {
  date: Date;
};

const ColumnHeader = (props: Props) => {
  const day = format(props.date, 'EEE');
  const num = format(props.date, 'd');

  const today = isToday(props.date);
  const classes = cx({
    [styles.today]: today,
    [styles.col]: !today,
  });

  return (
    <div className={classes}>
      <div className={styles.day}>{day}</div>
      <div className={styles.num}>{num}</div>
    </div>
  );
};

const ColumnHeaderResponsive = (props: Props) => {
  const day = format(props.date, 'EEE');
  const num = format(props.date, 'd');

  const today = isToday(props.date);

  return (
    <div className={cx(styles.device, styles.col)}>
      <div className={styles.day}>{day.toUpperCase()}</div>
      <div className={styles.num}>{num}</div>
    </div>
  );
};

type ColumnHeaderComponent =
  | typeof ColumnHeader
  | typeof ColumnHeaderResponsive;

const createColumnHeader = (Component: ColumnHeaderComponent) => {
  const columnHeaderHtml = (date: Date) => {
    return renderToStaticMarkup(<Component date={date} />);
  };

  return columnHeaderHtml;
};

export { ColumnHeader };
export { ColumnHeaderResponsive };
export { createColumnHeader };