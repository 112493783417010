import { forwardRef, useCallback, useRef } from 'react';
import styles from './style/ColorInput.module.css';

type Props = {
  className?: string;
  onPasteFormat?: (e: React.ClipboardEvent<HTMLInputElement>, ctx: PasteContext) => unknown;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>,
    | 'autoCapitalize'
    | 'autoComplete'
    | 'autoCorrect'
    | 'maxLength'
    | 'onBlur'
    | 'onChange'
    | 'type'>
  & Required<Pick<React.InputHTMLAttributes<HTMLInputElement>,
    | 'onBlur'
    | 'onChange'>>;

const HexInput = ({
  className,
  onBlur,
  onChange,
  onFocus,
  onPasteFormat,
  size = 6,
  tabIndex = 0,
  ...props
}: Props, ref: React.RefObject<HTMLInputElement>) => {
  const input = useRef(ref?.current || null);
  const focused = useRef(false);

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {

    onBlur?.(e);

    focused.current = false;

  }, [onBlur]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // e.persist()

    // const { selectionEnd, selectionStart } = e.target;

    // console.log(selectionStart, selectionEnd);

    onChange(e);

    // e.target.setSelectionRange(selectionStart, selectionEnd);

  }, [onChange]);

  const handleClick = useCallback((e: React.MouseEvent) => {

    if (e.target !== input.current && !focused.current) {
      input.current?.focus?.();
    }

  }, [input]);

  const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {

    onFocus?.(e);

    focused.current = true;

  }, [onFocus]);

  const handlePaste = useCallback((e: React.ClipboardEvent<HTMLInputElement>) => {
    const value = e.clipboardData.getData('text');
    const { selectionEnd, selectionStart } = input.current;

    const context = {
      clipboard: {
        length: value.length,
        value,
      },
      input: {
        length: input.current.value?.length || 0,
        value: input.current.value,
      },
      selection: {
        end: selectionEnd,
        length: Math.abs(selectionEnd - selectionStart),
        start: selectionStart,
      },
    };

    if (context.selection.length < context.input.length && context.input.length > 0) return;

    e.preventDefault();

    onPasteFormat(e, context);

  }, [
    input,
    onPasteFormat,
  ]);

  return (
    <div
      className={className}
      onClick={handleClick}>
      <span className={styles.prefix}>#</span>
      <input
        {...props}
        autoCapitalize="characters"
        autoComplete="off"
        autoCorrect="off"
        className={styles.input}
        maxLength={6}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onPaste={handlePaste}
        ref={input}
        size={size}
        tabIndex={tabIndex}
        type="text" />
    </div>
  );
};

HexInput.displayName = 'ColorInput.HexInput';

export const Input = forwardRef(HexInput);

export type PasteContext = {
  clipboard: {
    length: number;
    value:  string;
  };
  input: {
    length: number;
    value:  string;
  };
  selection: {
    end:    number;
    length: number;
    start:  number;
  };
};