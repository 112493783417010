import { memo, useMemo } from 'react';
import { format } from 'date-fns';
import type { ChatCitationType } from '@/enums';
import { KolProfileDataType, KolProfileDataTypeTables } from '@/enums/kol';
import type { Chat } from '@/types';
import { CitationsTable } from './Citation.Table';


type CitationDetailsProps<T extends ChatCitationType = ChatCitationType> = {
  data: Chat.CitationMetadata<T>;
};
type SnippetDetailsProps<T extends ChatCitationType = ChatCitationType> = {
  data: Chat.CitationSnippetMetadata<T>;
};

export const ContactDetailsCitation = memo(({ data }: CitationDetailsProps<ChatCitationType.ContactDetail>) => {

  const items = useMemo(() => {
    const tables = KolProfileDataTypeTables[KolProfileDataType.ContactInfo];

    return [
      data.phone && {
        name: 'Phone',
        value: data.phone,
      },
      data.email && {
        name: 'Email',
        value: data.email,
      },
      data.address && {
        name: 'Address',
        value: data.address,
      },
      data.primaryAffiliation && {
        name: 'Primary Affiliation',
        value: data.primaryAffiliation,
      },
      {
        name: 'Tables',
        value: tables.join(', '),
      },
      {
        name: 'Last Updated On',
        value: format(data.lastUpdatedOn, 'MM/dd/yyyy'),
      },
    ].filter(Boolean);
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

export const ClinicalTrialDetails = memo(({ data }: SnippetDetailsProps<ChatCitationType.ClinicalTrial>) => {

  const items = useMemo(() => {
    const tables = KolProfileDataTypeTables[KolProfileDataType.ClinicalTrials];

    return [
      {
        name: 'Name',
        value: data.name,
      },
      data.phase && {
        name: 'Phase',
        value: data.phase,
      },
      {
        name: 'Role',
        value: data.role,
      },
      data.startDate && {
        name: 'Start Date',
        value: data.startDate.toString(),
      },
      data.endDate && {
        name: 'End Date',
        value: data.endDate.toString(),
      },
      // {
      //   name: 'Country',
      //   value: data.country,
      // },
      {
        name: 'Tables',
        value: tables.join(', '),
      },
      {
        name: 'Last Updated On',
        value: format(data.lastUpdatedOn, 'MM/dd/yyyy'),
      },
    ].filter(Boolean);
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

export const CollaborationDetails = memo(({ data }: SnippetDetailsProps<ChatCitationType.Collaboration>) => {

  const items = useMemo(() => {
    const tables = KolProfileDataTypeTables[KolProfileDataType.Collaborations];

    return [
      {
        name: 'Company',
        value: data.companyName,
      },
      {
        name: 'Collaboration Type',
        value: data.collaborationType.join(', '),
      },
      {
        name: 'Tables',
        value: tables.join(', '),
      },
      {
        name: 'Last Updated On',
        value: format(data.lastUpdatedOn, 'MM/dd/yyyy'),
      },
    ].filter(Boolean);
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

export const CompanySponsorshipDetails = memo(({ data }: SnippetDetailsProps<ChatCitationType.CompanySponsorship>) => {

  const items = useMemo(() => {
    const tables = KolProfileDataTypeTables[KolProfileDataType.Sponsorships];

    return [
      {
        name: 'Company',
        value: data.companyName,
      },
      {
        name: 'Has sponsored event',
        value: data.hasSponsoredEvent ? 'Yes' : 'No',
      },
      {
        name: 'Has sponsored clinical trial',
        value: data.hasSponsoredClinicalTrial ? 'Yes' : 'No',
      },
      {
        name: 'Tables',
        value: tables.join(', '),
      },
      {
        name: 'Last Updated On',
        value: format(data.lastUpdatedOn, 'MM/dd/yyyy'),
      },
    ].filter(Boolean);
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

export const AffiliationDetails = memo(({ data }: SnippetDetailsProps<ChatCitationType.Affiliation>) => {

  const items = useMemo(() => {
    const tables = KolProfileDataTypeTables[KolProfileDataType.Affiliations];

    return [
      {
        name: 'Name',
        value: data.name,
      },
      data.role && {
        name: 'Role',
        value: data.role,
      },
      data.department && {
        name: 'Department',
        value: data.department,
      },
      {
        name: 'Address',
        value: [data.street, data.city, data.state, data.zip, data.country].filter(Boolean).join(', '),
      },
      {
        name: 'Tables',
        value: tables.join(', '),
      },
      {
        name: 'Last Updated On',
        value: format(data.lastUpdatedOn, 'MM/dd/yyyy'),
      },
    ].filter(Boolean);
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

export const AssociationDetails = memo(({ data }: SnippetDetailsProps<ChatCitationType.Association>) => {

  const items = useMemo(() => {
    const tables = KolProfileDataTypeTables[KolProfileDataType.Associations];

    return [
      {
        name: 'Name',
        value: data.name,
      },
      {
        name: 'Title',
        value: data.jobTitle,
      },
      {
        name: 'Position Type',
        value: data.positionType,
      },
      {
        name: 'Is Current',
        value: data.isCurrent ? 'Yes' : 'No',
      },
      {
        name: 'Tables',
        value: tables.join(', '),
      },
      {
        name: 'Last Updated On',
        value: format(data.lastUpdatedOn, 'MM/dd/yyyy'),
      },
    ].filter(Boolean);
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

export const ConferenceDetails = memo(({ data }: SnippetDetailsProps<ChatCitationType.Conference>) => {

  const items = useMemo(() => {
    const tables = KolProfileDataTypeTables[KolProfileDataType.Conferences];

    return [
      {
        name: 'Name',
        value: data.name,
      },
      {
        name: 'Position',
        value: data.position,
      },
      {
        name: 'Talk Title',
        value: data.talkTitle,
      },
      data.startDate && {
        name: 'Start Date',
        value: data.startDate.toString(),
      },
      data.endDate && {
        name: 'End Date',
        value: data.endDate.toString(),
      },
      {
        name: 'Tables',
        value: tables.join(', '),
      },
      {
        name: 'Last Updated On',
        value: format(data.lastUpdatedOn, 'MM/dd/yyyy'),
      },
    ];
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

export const PublicationDetails = memo(({ data }: SnippetDetailsProps<ChatCitationType.Publication>) => {

  const items = useMemo(() => {
    const tables = KolProfileDataTypeTables[KolProfileDataType.Publications];

    return [
      {
        name: 'Title',
        value: data.title,
      },
      {
        name: 'Journal',
        value: data.journal,
      },
      {
        name: 'Authorship Position',
        value: data.authorshipPosition,
      },
      {
        name: 'Publication Date',
        value: data.publicationDate.toString(),
      },
      {
        name: 'Publication Type',
        value: data.publicationType,
      },
      // {
      //   name: 'Country',
      //   value: data.country,
      // },
      {
        name: 'Tables',
        value: tables.join(', '),
      },
      {
        name: 'Last Updated On',
        value: format(data.lastUpdatedOn, 'MM/dd/yyyy'),
      },
    ];
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

export const DigialLeaderTopicDetails = memo(({ data }: SnippetDetailsProps<ChatCitationType.DigitalLeaderTopic>) => {

  const items = useMemo(() => {
    const tables = KolProfileDataTypeTables[KolProfileDataType.LeaderTopics];

    return [
      {
        name: 'Name',
        value: data.name,
      },
      {
        name: 'Decile',
        value: data.scoreDecile,
      },
      {
        name: 'Tables',
        value: tables.join(', '),
      },
      {
        name: 'Last Updated On',
        value: format(data.lastUpdatedOn, 'MM/dd/yyyy'),
      },
    ];
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

export const ScientificLeaderTopicDetails = memo(({ data }: SnippetDetailsProps<ChatCitationType.ScientificLeaderTopic>) => {

  const items = useMemo(() => {
    const tables = KolProfileDataTypeTables[KolProfileDataType.LeaderTopics];

    return [
      {
        name: 'Name',
        value: data.name,
      },
      {
        name: 'Type',
        value: data.type,
      },

      {
        name: 'Decile',
        value: data.scoreDecile,
      },
      {
        name: 'Tables',
        value: tables.join(', '),
      },
      {
        name: 'Last Updated On',
        value: format(data.lastUpdatedOn, 'MM/dd/yyyy'),
      },
    ];
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

export const ClinicalLeaderTopicDetails = memo(({ data }: SnippetDetailsProps<ChatCitationType.ClinicalLeaderTopic>) => {

  const items = useMemo(() => {
    const tables = KolProfileDataTypeTables[KolProfileDataType.LeaderTopics];

    return [
      {
        name: 'Name',
        value: data.name,
      },
      {
        name: 'Type',
        value: data.type,
      },

      {
        name: 'Decile',
        value: data.scoreDecile,
      },
      {
        name: 'Tables',
        value: tables.join(', '),
      },
      {
        name: 'Last Updated On',
        value: format(data.lastUpdatedOn, 'MM/dd/yyyy'),
      },
    ];
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

export const UnmetNeedDetails = memo(({ data }: SnippetDetailsProps<ChatCitationType.UnmetNeed>) => {
  const items = useMemo(() => {
    const tables = KolProfileDataTypeTables[KolProfileDataType.MunData];

    return [
      {
        name: 'Name',
        value: data.name,
      },
      {
        name: 'Description',
        value: data.description,
      },
      {
        name: 'Tables',
        value: tables.join(', '),
      },
      {
        name: 'Last Updated On',
        value: format(data.lastUpdatedOn, 'MM/dd/yyyy'),
      },
    ];
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});