import { generateEmptyChatSessionFiltersState } from '@/components/Chat.State/state.initial-state';
import { ChatContextType } from '@/enums';
import type { GroupFeatures } from '@/store/interfaces';
import type { BrandInsightsPageContext, Chat } from '@/types';

export function calculateIfFiltersActive(data: CalculateActiveCount) {
  return calculateActiveFiltersCount(data) > 0;
}

export function calculateActiveFiltersCount({ context, data, features }: CalculateActiveCount) {
  let count = data.custom.length;
  const defaults = generateEmptyChatSessionFiltersState({ context, features });

  // Check sources
  if (JSON.stringify((data.sources || []).concat().sort((a, b) => a.id.localeCompare(b.id))) != JSON.stringify(defaults.sources.concat().sort((a, b) => a.id.localeCompare(b.id)))) count++;
  // Check Publish date
  if (data.minDocumentDate != defaults.minDocumentDate) count++;
  // Check tags
  if (data.tags.length) count++;
  // Check data sources
  if (JSON.stringify((data.dataSources || []).concat().sort((a, b) => a.id.localeCompare(b.id))) != JSON.stringify(defaults.dataSources.concat().sort((a, b) => a.id.localeCompare(b.id)))) count++;
  // Check records
  if (data.records?.length && context.type === ChatContextType.Global) {
    count += data.records.length;
  }

  return count;
}

type CalculateActiveCount = {
  context: BrandInsightsPageContext.Item;
  data: Chat.Filters;
  features: GroupFeatures;
};