import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as api from '@/api';
import { TabViewTableSkeleton } from '@/components/TabView';
import { UploadFilesContext } from '@/containers/WorkspaceFileUpload/Context';
import { useInitiateFilesReview } from '@/containers/WorkspaceFileUpload/hooks/useInitiateFilesReview';
import { FileUploadReviewContainer } from '@/containers/WorkspaceFileUpload/Upload.Review';
import { ProjectDetailsContext, ProjectStateLoadingContext } from '@/screens/Project.Main/Context';
import { FilesTable, FilesTableDataContext, FilesTableLoadingContext } from '@/screens/Workspace.FilesTable';
import { FilesActionsButton } from './Project.Files.Actions';
import styles from './style/Project.Files.module.css';

type Props = unknown;

const ProjectFilesTable = (props: Props) => {

  const loading = useContext(FilesTableLoadingContext);

  return (
    <div>
      {loading
        ? <TabViewTableSkeleton />
        : (
          <div className={styles.root}>
            <div className={styles.wrap}>
              <FilesTable
                Actions={FilesActionsButton}
                classes={{
                  root: styles.content,
                  table: styles.table,
                  header: styles.header,
                }} />
            </div>
          </div>
        )
      }
    </div>
  );
};

type Params = {
  projectId: string;
};

const ProjectFiles = (props: Props) => {
  const ctx = {
    details: useContext(ProjectDetailsContext),
    loading: useContext(ProjectStateLoadingContext),
  };
  const params = useParams<Params>();

  const query = useQuery({
    queryKey: [
      `get:projects/files`,
      +params.projectId,
      ctx.details.query.data?.project?.parentId,
    ],
    queryFn: () => {
      return api.projects.fetchProjectFiles({
        projectId: +params.projectId,
      });
    },
    enabled: !ctx.loading,
  });

  const uploadFiles = useInitiateFilesReview({
    parentObjectId: ctx.details.query.data?.object?.id,
    workspaceId: ctx.details.query.data?.project?.workspaceId,
  });

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <FilesTableDataContext.Provider value={query.data?.items || []}>
      <FilesTableLoadingContext.Provider value={query.isInitialLoading}>
        <UploadFilesContext.Provider value={uploadFiles}>
          <ProjectFilesTable />
        </UploadFilesContext.Provider>
      </FilesTableLoadingContext.Provider>
    </FilesTableDataContext.Provider>
  );
};

const ProjectFilesContainer = (_: unknown) => {
  return (
    <FileUploadReviewContainer>
      <ProjectFiles />
    </FileUploadReviewContainer>
  );
};

export { ProjectFilesContainer as ProjectFiles };