import { memo, useContext } from 'react';
import { Header } from '@/components/Modal/Header';
import { Button } from '@/components/presentation/Button';
import { FileUploadReviewStepperActionsContext } from './context';
import styles from './style/Review.Transcription.module.css';

export const TranscriptionReview = () => {

  const stepperActions = useContext(FileUploadReviewStepperActionsContext);

  const copy = `Some of the files you're uploading can be transcribed. Click continue to select which ones to transcribe.`;

  return (
    <div className={styles.root}>
      <Header text="Want to transcribe these audio files?" />

      <div className={styles.body}>
        <TranscriptImage />
        <div className={styles.copy}>{copy}</div>
      </div>

      <div className={styles.footer}>
        <Button.Primary
          variant="brick"
          onClick={stepperActions.next}>
          Continue
        </Button.Primary>
      </div>
    </div>
  );
};

const TranscriptImage = memo(() => {

  return (
    <svg
      className={styles.image}
      width="202px"
      height="93px"
      viewBox="0 0 202 93">
      <g
        id="Transcript-Import"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="4" transform="translate(-356.000000, -268.000000)">
          <g id="Group-5" transform="translate(358.000000, 270.000000)">
            <path
              d="M45,28.6875 L124,0 L119,8 L119,76.000305 L124,81 L45,56.109375 C46.6818646,49.9435572 47.5227968,45.3029322 47.5227968,42.1875 C47.5227968,39.0720678 46.6818646,34.5720678 45,28.6875 Z"
              id="Rectangle"
              fill="#E2F4FB" />
            <g
              id="play-circle-(2)"
              transform="translate(0.000000, 19.000000)"
              stroke="#9B9B9B"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4">
              <circle
                id="Oval"
                cx="23.5"
                cy="23.5"
                r="23.5" />
              <polygon id="Path" points="18.4642857 14.2678571 32.7321429 23.5 18.4642857 32.7321429" />
            </g>
            <path
              d="M158.934827,81 L127,81 C122.581722,81 119,77.418278 119,73 L119,8 C119,3.581722 122.581722,0 127,0 L177,0 C181.418278,0 185,3.581722 185,8 L185,49"
              id="Path"
              stroke="#26AAE1"
              strokeWidth="4"
              strokeLinecap="round" />
            <rect
              id="Rectangle"
              fill="#C3E9F7"
              x="128"
              y="12"
              width="14"
              height="6"
              rx="3" />
            <rect
              id="Rectangle"
              fill="#C3E9F7"
              x="145"
              y="12"
              width="30"
              height="6"
              rx="3" />
            <rect
              id="Rectangle"
              fill="#C3E9F7"
              x="161"
              y="21"
              width="14"
              height="6"
              rx="3" />
            <rect
              id="Rectangle"
              fill="#C3E9F7"
              x="128"
              y="21"
              width="30"
              height="6"
              rx="3" />
            <rect
              id="Rectangle"
              fill="#C3E9F7"
              x="154"
              y="30"
              width="11"
              height="6"
              rx="3" />
            <rect
              id="Rectangle"
              fill="#C3E9F7"
              x="128"
              y="30"
              width="23"
              height="6"
              rx="3" />
            <rect
              id="Rectangle"
              fill="#C3E9F7"
              x="161"
              y="46"
              width="14"
              height="6"
              rx="3" />
            <rect
              id="Rectangle"
              fill="#C3E9F7"
              x="128"
              y="46"
              width="30"
              height="6"
              rx="3" />
            <rect
              id="Rectangle"
              fill="#C3E9F7"
              x="128"
              y="55"
              width="14"
              height="6"
              rx="3" />
            <rect
              id="Rectangle"
              fill="#C3E9F7"
              x="145"
              y="55"
              width="20"
              height="6"
              rx="3" />
            <rect
              id="Rectangle"
              fill="#C3E9F7"
              x="154"
              y="64"
              width="8"
              height="6"
              rx="3" />
            <rect
              id="Rectangle"
              fill="#C3E9F7"
              x="128"
              y="64"
              width="23"
              height="6"
              rx="3" />
            <g
              id="play-circle-(2)"
              transform="translate(165.000000, 56.000000)"
              stroke="#546EB2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4">
              <circle
                id="Oval"
                cx="16.5"
                cy="16.5"
                r="16.5" />
              <polygon id="Path" points="13 10 23 16.5 13 23" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
});