import type { Branding } from '@/api/interfaces/groups';
import { getAuthenticatedClient } from './client';

export const createPalette = (data: Branding.Palettes.CreatePalette.Request): Promise<Branding.Palettes.CreatePalette.Response> => {
  const { groupId, ...params } = data;

  return getAuthenticatedClient().post(`/groups/${groupId}/branding/palettes`, params)
    .then(res => res.data);
};

export const deletePalette = (data: Branding.Palettes.DeletePalette.Request) => {
  const { groupId, paletteId } = data;

  return getAuthenticatedClient().delete<Branding.Palettes.DeletePalette.Response>(`/groups/${groupId}/branding/palettes/${paletteId}`);
};

export const updatePalette = (data: Branding.Palettes.UpdatePalette.Request): Promise<Branding.Palettes.UpdatePalette.Response> => {
  const { groupId, ...params } = data;

  return getAuthenticatedClient().put(`/groups/${groupId}/branding/palettes/${params.paletteId}`, params);
};