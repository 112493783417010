import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { config } from '@/config';

export const DocumentHelmet = memo(() => {
  const description = `.`;
  const title = '';

  return (
    <Helmet
      defaultTitle={title}
      titleTemplate={`%s`}>
      <meta name="robots" content="noindex" />
      <link
        rel="canonical"
        href={`${config().FRONT_BASE_URL}/`} />
      <meta
        name='description'
        content={description} />
      <meta
        name='og:description'
        content={description} />
    </Helmet>
  );
});