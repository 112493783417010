import { useContext, useMemo } from 'react';
import { FileUploadStatus } from '@/enums';
import { FileUploadsStateContext } from '../Context';

export const useUploadInProgress = () => {
  const state = useContext(FileUploadsStateContext);

  const inProgress = useMemo(() =>
    state.valid.some(s => [FileUploadStatus.InProgress, FileUploadStatus.Enqueued].includes(s.status))
  , [state.valid],
  );

  return inProgress;
};

export default useUploadInProgress;