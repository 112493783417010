import queryString from 'query-string';
import { getAuthenticatedClient } from './client';
import type * as ChatApi from './interfaces/chat';
import {
  prepareContextData,
  stringifyContextData,
  transformChatInstance,
  transformChatFileUpload,
  transformChatQuery,
} from './transformers';

export const submitChatQuery = async (data: ChatApi.SubmitChatQuery.Request) => {
  const postData = {
    ...data,
    context: prepareContextData(data.context),
  };
  const res = await getAuthenticatedClient().post<ChatApi.SubmitChatQuery.Response>('/chat/submit-query', postData);
  return {
    identifier: res.data.identifier,
  };
};

export const regenerateQuery = async (data: ChatApi.RegenerateQuery.Request) => {
  const postData = {
    ...data,
    context: prepareContextData(data.context),
  };
  const res = await getAuthenticatedClient().post<ChatApi.RegenerateQuery.Response>(`/chat/regenerate-query`, postData);
  return {
    identifier: res.data.identifier,
  };
};

export const getChatHistory = async (data: ChatApi.GetChatHistory.Request): Promise<ChatApi.GetChatHistory.Response<Date>> => {
  const context = stringifyContextData(prepareContextData(data.context));

  const qs = queryString.stringify({
    cursor: data.cursor,
    pageSize: data.pageSize,
    context,
    mode: data.mode,
    includeAdditional: data.includeAdditional,
  }, {
    arrayFormat: 'bracket',
    skipNull: true,
  });

  const res = await getAuthenticatedClient().get<ChatApi.GetChatHistory.Response>(`/chat/history?${qs}`);

  return {
    hasMore: res.data.hasMore,
    items: res.data.items.map(transformChatInstance),
    shared: res.data.shared.map(transformChatInstance),
  };
};

export const getChatHints = async (data: ChatApi.GetChatHints.Request) => {
  const context = stringifyContextData(prepareContextData(data.context));

  const qs = queryString.stringify({
    context,
  }, {
    skipNull: true,
  });

  const res = await getAuthenticatedClient().get<ChatApi.GetChatHints.Response>(`/chat/hints?${qs}`);
  return res.data;
};

export const uploadChatFiles = async (data: ChatApi.UploadFiles.Request): Promise<ChatApi.UploadFiles.Response> => {
  const postData = {
    ...data,
    context: prepareContextData(data.context),
  };
  const res = await getAuthenticatedClient().post<ChatApi.UploadFiles.Response<string>>('/chat/upload-documents', postData);
  return {
    chatInstance: transformChatInstance(res.data.chatInstance),
    fileUpload: transformChatFileUpload(res.data.fileUpload),
  };
};

export const lookupContext = async (data: ChatApi.LookupContext.Request) => {
  const qs = queryString.stringify({
    contextType: data.contextType,
    entityId: data.entityId,
    objectId: data.objectId,
  });

  const res = await getAuthenticatedClient().get<ChatApi.LookupContext.Response>(`/chat/context?${qs}`);
  return res.data;
};

export const getChatContextAccess = async (data: ChatApi.GetChatContextAccess.Request) => {

  const context = stringifyContextData(prepareContextData(data.context));

  const qs = queryString.stringify({
    context,
  }, {
    skipNull: true,
  });

  const res = await getAuthenticatedClient().get<ChatApi.GetChatContextAccess.Response>(`/chat/context-access?${qs}`);
  return res.data;
};

export const searchChatFilters = async (data: ChatApi.SearchChatFilters.Request) => {
  const res = await getAuthenticatedClient().get<ChatApi.SearchChatFilters.Response>(`/chat/filters/search?${queryString.stringify(data, { arrayFormat: 'bracket' })}`);
  return res.data;
};

export const getPendingQuery = async (data: ChatApi.GetPendingQuery.Request) => {
  return getAuthenticatedClient().get<ChatApi.GetPendingQuery.Response>(`/chat/pending-query?queryIdentifier=${data.queryIdentifier}`)
    .then(res => ({
      chatInstance: transformChatInstance(res.data.chatInstance),
      query: transformChatQuery(res.data.query),
    }));
};

export const contextSearch = ({ type, q }: ChatApi.ContextSearch.Request) => {
  return getAuthenticatedClient().get<ChatApi.ContextSearch.Response>(`/chat/context-search?${queryString.stringify({ type, q })}`);
};

export const searchTags = async (data: ChatApi.SearchTags.Request) => {
  const context = stringifyContextData(prepareContextData(data.context));

  const qs = queryString.stringify({
    context,
    query: data.query,
  }, {
    skipNull: true,
  });

  return getAuthenticatedClient().get<ChatApi.SearchTags.Response>(`/tags?${qs}`);
};

export const submitChatAction = async (data: ChatApi.SubmitChatAction.Request) => {
  return getAuthenticatedClient().post<ChatApi.SubmitChatAction.Response>('/chat/actions', data);
};