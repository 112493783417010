import { createSlice } from '@reduxjs/toolkit';
import type { WorkspaceState } from './interfaces';
import { sharedActions } from './shared-actions';

export const workspaceSlice = createSlice({
  name: 'workspaces',
  initialState: null as WorkspaceState,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(sharedActions.appStatedLoaded, (state, action) => {
      return action.payload.workspace;
    });
  },
});

export default workspaceSlice.reducer;