// import { GroupType, Role } from '@/enums';
import type {
  AvailableEventMetadata,
  CalendarISOEvent,
  ScheduledEventMetadata,
} from '@/types';
import { trunc } from '@/utils';
import { safeJsonDate } from '@/utils/date';
import type { FullCalEvent, ExternalEventsMap /* , ExternalEventsMap*/ } from './interfaces';
import styles from './style/Calendar.module.css';

type UnparsedCalendarViewEvent =
  CalendarISOEvent<
    AvailableEventMetadata
  | ScheduledEventMetadata<string>>;

type TransformExternalEventsParams = {
  events: { [uid: string]: UnparsedCalendarViewEvent[] };
  userId: number;
};

type TransformEventParams = {
  // group: {
  //   typeId: GroupType;
  // };
  event: UnparsedCalendarViewEvent;
  // user: {
  //   roles: Role[];
  // };
};

type ResolveScheduledEventTitle = {
  // group: {
  //   typeId: GroupType;
  // };
  metadata: ScheduledEventMetadata;
  // user: {
  //   roles: Role[];
  // };
};

export function transformCalendarEventMetadata(metadata: ScheduledEventMetadata<string> | AvailableEventMetadata)  {
  const castMetadata = (metadata as ScheduledEventMetadata<string>);
  const call = castMetadata.call;
  if (!call) return metadata as AvailableEventMetadata;

  return {
    ...castMetadata,
    // call: {
    //   ...call,
    //   approvedOn: safeJsonDate(call.approvedOn),
    //   createdAt: safeJsonDate(call.createdAt),
    //   screenedOn: safeJsonDate(call.screenedOn),
    //   timeStart: safeJsonDate(call.timeStart),
    //   timeEnd: safeJsonDate(call.timeEnd),
    // },
  } as ScheduledEventMetadata;
}

export const createId = getIncrementor();
/*
function resolveScheduledEventTitle({ group, metadata, user }: ResolveScheduledEventTitle) {
  if (hasClientRole(user) && isInternalUser(group)) {
    return metadata.respondent.profile.fullname;
  }

  return trunc(metadata.call.title, 30);
}
*/
export const transformEvent = ({ event }: TransformEventParams): FullCalEvent => {

  if (event.isBusy) {
    const e = event as CalendarISOEvent<ScheduledEventMetadata<string>>;
    const metadata = transformCalendarEventMetadata(e.metadata) as ScheduledEventMetadata;
    return {
      id: createId(),
      startEditable: false,
      durationEditable: false,
      extendedProps: {
        isNew: false,
        type: 'busy',
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        segments: e.segments,
        metadata,
        canRemove: false,
      },
      end: new Date(event.end),
      start: new Date(event.start),
      title: 'Event', // resolveScheduledEventTitle({ group, metadata, user }),
      backgroundColor: 'var(--gray-d)',
      borderColor: 'var(--gray-d)',
      classNames: [styles.busy],
    };
  } else {
    const e = event as CalendarISOEvent<AvailableEventMetadata>;
    return {
      id: createId(),
      startEditable: false,
      durationEditable: false,
      extendedProps: {
        isNew: false,
        type: 'available',
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        segments: e.segments,
        metadata: transformCalendarEventMetadata(e.metadata) as AvailableEventMetadata,
        canRemove: true,
      },
      end: new Date(e.end),
      start: new Date(e.start),
      title: 'Available',
      backgroundColor: '',
      borderColor: '',
      classNames: [styles.available],
    };
  }
};

export const transformExternalEvents = ({ userId, events }: TransformExternalEventsParams): ExternalEventsMap => {
  return Object.keys(events).reduce<ExternalEventsMap>((acc, uid) => {
    const isYou = +uid === userId;
    const items: FullCalEvent[] = events[uid].map(event => ({
      id: createId(),
      extendedProps: {
        isNew: false,
        type: 'external',
        canRemove: false,
      },
      startEditable: false,
      durationEditable: false,
      end: new Date(event.end),
      start: new Date(event.start),
      title: event.summary,
      textColor: 'var(--black)',
      backgroundColor: isYou ? 'var(--gray-l)' : '#F8A8A8',
      borderColor: isYou ? 'var(--gray-l)' : '#F8A8A8',
      classNames: [],
    }));
    return {
      ...acc,
      [uid]: items,
    };
  }, {});
};

function getIncrementor() {
  const state = {
    id: 0,
    inc: () => {
      state.id += 1;

      return state.id;
    },
  };

  return () => state.inc();
}