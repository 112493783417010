import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as api from '@/api';
import { ProjectDetailsContext, ProjectStateLoadingContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const ProjectContainer = (props: Props) => {
  const [canFetch, setCanFetch] = useState(false);

  const params = useParams<{ projectId: string }>();
  const projectId = +params.projectId;

  const query = useQuery({
    queryKey: [`get:projects`, projectId],
    queryFn: async () => {
      return api.projects.fetchProject({
        projectId,
      });
    },
    enabled: canFetch,
  });

  useEffect(() => {

    const timeout = setTimeout(() => {
      setCanFetch(true);
    }, 3000);

    return () => clearTimeout(timeout);

  }, []);

  return (
    <ProjectStateLoadingContext.Provider value={!query.data || query.isInitialLoading}>
      <ProjectDetailsContext.Provider value={{ query }}>
        {props.children}
      </ProjectDetailsContext.Provider>
    </ProjectStateLoadingContext.Provider>
  );
};

ProjectContainer.displayName = 'Project.Container';