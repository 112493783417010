import type { ChangeEvent } from 'react';
import { forwardRef, memo, useCallback, useContext, useMemo, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import { useZIndexModifier, PopperMenu, PopperMenuItem } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import { Button } from '@/components/presentation/Button';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { UploadFilesContext } from '@/containers/WorkspaceFileUpload/Context';
import { FilesTableContext, FilesTableMetaContext } from '@/screens/Workspace.FilesTable/context';
import styles from '@/screens/Workspace.FilesTable/style/Files.Table.Actions.module.css';

type Props = {
  className?: string;
};

const AnchorButton = memo(forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { palette, theme: wl } = useContext(AppThemingContext);

  if (wl?.enabled) {
    return (
      <Button
        ref={ref}
        color="transparent"
        className={props.className}
        style={{ backgroundColor: palette.primary.main, color: palette.primary.text }}
        variant="brick">
        Actions
      </Button>
    );
  }

  return (
    <Button
      ref={ref}
      color="primary"
      className={props.className}
      variant="brick">
      Actions
    </Button>
  );
}));

type ButtonProps = {
  className?: string;
};

type MenuItem = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick: () => unknown;
};

export const FilesActionsButton = ({ className }: ButtonProps) => {

  const [open, setOpen] = useState(false);

  const onUpload = useContext(UploadFilesContext);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleUploadClick = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files.length) {
      onUpload(Array.from(e.target.files));
    }
    setOpen(false);
  }, [
    onUpload,
  ]);

  const uploadFileItem: MenuItem = useMemo(() => {
    const buttonText = `Upload File(s)`;
    return {
      className: styles.noPadding,
      disabled: false,
      children: (
        <label>
          <input
            className={styles.fileInput}
            multiple={true}
            onChange={handleUploadClick}
            type="file" />
          {buttonText}
        </label>
      ),
      onClick: () => { },
    };
  }, [handleUploadClick]);

  const items = [
    uploadFileItem,
  ];

  return (
    <>
      <div
        className={className}
        ref={setReferenceElement}
        onClick={handleClick}>
        <AnchorButton />
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PopperMenu>
                {items.map((m, i) => {
                  return (
                    <PopperMenuItem
                      key={i}
                      {...m}
                      className={styles.menuItem}
                      onClick={m.onClick} />
                  );
                })}
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};