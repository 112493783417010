import { memo, useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { ActiveChatSessionContext, ChatSessionFiltersContext, ChatSettingsContext } from '@/components/Chat/context';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';
import { generateEmptyChatSessionFiltersState } from '@/components/Chat.State/state.initial-state';
import { ChatContextType } from '@/enums';
import { ChatSource, ChatSourcesMap } from '@/enums/chat.filters';
import { useAppSelector } from '@/store';
import { useHasHcpProfilesEnabled } from '@/store/selectors';
import type { RecordFilter } from '@/types/chat.filters';
import { PublishedDate } from './PublishedDate';
import { Record } from './Record';
import { Source } from './Source';
import { Tag } from './Tag';

type Props = {
  active: boolean;
};

export function FilterPaneWorkspace(props: Props) {
  return (
    <Root>
      <Header>
        <HeaderLabel>Source Filters</HeaderLabel>
        <ResetController {...props} />
      </Header>
      <Filters>
        <PublishedDateController />
        <TagController />
        <SourceController />
        <RecordController />
      </Filters>
    </Root>
  );
}

function RecordController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const session = useContext(ActiveChatSessionContext);
  const hasProfilesEnabled = useHasHcpProfilesEnabled();

  const value = useMemo(() => {
    return filters.records?.length ? filters.records[0] : null;
  }, [filters.records]);

  const handleChange = useCallback((data: RecordFilter.Item) => {
    dispatch({
      type: 'filters/updated',
      payload: {
        filters: {
          records: [data],
        },
      },
    });
  }, [dispatch]);

  if (!hasProfilesEnabled) {
    return null;
  }

  if (session.context.type !== ChatContextType.Global) {
    return null;
  }

  return (
    <>
      <Record
        onChange={handleChange}
        value={value} />
    </>
  );
}


function SourceController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const settings = useContext(ChatSettingsContext);

  const options = useMemo(() => {
    const kolSources = [
      ChatSource.Profiles,
      ChatSource.MslInteractions,
    ].map(m => ({
      id: m,
      name: ChatSourcesMap.get(m),
    }));

    const workspaceSources = [
      ChatSource.Presentations,
      ChatSource.Text,
    ].map(m => ({
      id: m,
      name: ChatSourcesMap.get(m),
    }));

    return [
      ...(settings.workspaces ? workspaceSources : []),
      ...(settings.hcpProfiles ? kolSources : []),
    ];
  }, [settings.hcpProfiles, settings.workspaces]);

  if (!options.length) return null;

  return (
    <Source
      options={options}
      value={filters.sources}
      onChange={sources => {
        dispatch({
          type: 'filters/updated',
          payload: {
            filters: {
              sources: sources.length ? sources : options,
            },
          },
        });
      }} />
  );
}

function PublishedDateController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const { context } = useContext(ActiveChatSessionContext);
  const groupFeatures = useAppSelector(state => state.group.features);

  const defaultValue = useMemo(() => generateEmptyChatSessionFiltersState({
    context,
    features: groupFeatures,
  }).minDocumentDate, [context, groupFeatures]);

  return (
    <PublishedDate
      defaultValue={defaultValue}
      value={filters.minDocumentDate}
      onChange={minDocumentDate => {
        dispatch({
          type: 'filters/updated',
          payload: {
            filters: {
              minDocumentDate,
            },
          },
        });
      }} />
  );
}

function TagController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const { context } = useContext(ActiveChatSessionContext);

  return (
    <Tag
      context={context}
      value={filters.tags}
      onChange={tags => {
        dispatch({
          type: 'filters/updated',
          payload: {
            filters: {
              tags,
            },
          },
        });
      }} />
  );
}

const ResetController = memo(({ active }: Props) => {
  const dispatch = useContext(ChatStateDispatchContext);

  return (
    <HeaderReset
      disabled={!active}
      onClick={() => dispatch({ type: 'filters/reset' })}>
      Reset
    </HeaderReset>
  );
});

const Root = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: 5,
  border: `1px solid ${theme.palette.gray.light1}`,
  boxShadow: '0 2px 6px 2px rgba(60, 64, 67, .15)',
  boxSizing: 'border-box',
  padding: 12,
  minWidth: 450,
  maxWidth: 600,
}));

const Header = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
});

const HeaderLabel = styled.h1(({ theme }) => ({
  fontSize: '18px',
  fontFamily: theme.fonts.semiBold,
}));

const HeaderReset = styled.button(({ disabled, theme }) => ({
  color: !disabled ? theme.palette.text.primary : theme.palette.gray.light1,
  fontSize: '14px',
  transition: 'text-decoration 150ms, color 150ms',

  ':hover': {
    textDecoration: disabled ? 'underline' : 'none',
  },
}));

const Filters = styled.div({
  boxSizing: 'border-box',
  padding: '0 2px',
});