import { type ComponentProps, useContext } from 'react';
import RadioMUI from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import { AppThemingContext } from '@/containers/AppTheming/Context';

type Props = Omit<ComponentProps<typeof RadioMUI>, 'color' | 'disableRipple'>;

type StyledRadioProps = {
  checkedColor: string;
};

const StyledRadio = styled(RadioMUI, {
  shouldForwardProp: prop => prop !== 'checkedColor',
})<StyledRadioProps>(({ checkedColor }) => ({
  padding: 0,
  [`&:hover`]: {
    backgroundColor: 'transparent',
  },
  [`&.Mui-checked`]: {
    color: checkedColor,
  },
}));

export const Radio = (props: Props) => {
  const { palette, theme } = useContext(AppThemingContext);
  const checkedColor = theme
    ? palette.primary.main
    : `var(--pri-02)`;

  return (
    <StyledRadio
      className={props.className}
      disableRipple
      color='default'
      checkedColor={checkedColor}
      {...props} />
  );
};