import type { Branding } from '@/api/interfaces/groups';
import { getAuthenticatedClient } from './client';

export const fetchBranding = (data: Branding.FetchBranding.Request) => {
  return getAuthenticatedClient().get<Branding.FetchBranding.Response>(`/groups/${data.groupId}/branding`)
    .then(res => res.data);
};

export const setColorCategoryTheme = (data: Branding.SetColorCategoryTheme.Request): Promise<Branding.SetColorCategoryTheme.Response> => {
  const { groupId, ...params } = data;

  return getAuthenticatedClient().put(`/groups/${groupId}/branding/theming`, params);
};

export * as palettes from './groups.branding.palettes';