import { useQuery } from '@tanstack/react-query';
import * as $api from '@/api/chat';
import useDebounceValue from '@/hooks/useDebounceValue';

type Props = {
  enabled?: boolean;
  excluded: (number | string)[];
  type: 'product' | 'therapeutic-sub-area' | 'interest-area' | 'therapeutic-area' | 'hcps';
  value: string;
};

export const useFilterSearchQuery = ({
  enabled = true,
  ...props
}: Props) => {

  const debouncedProps = useDebounceValue(props, 300);

  const query = useQuery({
    queryKey: ['get:chat/filters', debouncedProps],
    queryFn: ({ queryKey: [_, data] }) => {
      return $api.searchChatFilters(data as Props);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    placeholderData: {
      results: [],
    },
    enabled,
  });

  return query;
};