import { useMemo } from 'react';
import { ProjectDetails } from '@/screens/Project.Details';
import { ProjectEntities } from '@/screens/Project.Entities';
import { ProjectFiles } from '@/screens/Project.Files';
import type { SceneMap } from './interfaces';
import { RouteKey } from './interfaces';
import { ProjectTabView } from './Project.TabView';

type Props = {
  paths?: {
    details: string;
    entities: string;
    files: string;
  };
};

export const ProjectTabViewContainer = ({
  paths = {
    details: `details`,
    entities: `entities`,
    files: `files`,
  },
}: Props) => {
  const map: SceneMap = useMemo(() => ({
    [RouteKey.Entities]: {
      component: ProjectEntities,
      path: paths.entities,
      title: 'Entities',
    },
    [RouteKey.Files]: {
      component: ProjectFiles,
      path: paths.files,
      title: 'Files',
    },
    [RouteKey.Details]: {
      component: ProjectDetails,
      path: paths.details,
      title: 'Details',
    },
  }), [paths]);

  return <ProjectTabView map={map} />;
};