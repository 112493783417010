import { useCallback, useContext } from 'react';
import type { CompleteMultipartUploadOutput } from '@aws-sdk/client-s3';
import { S3 } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import type { WorkspaceFileUpload } from '@/types';
import { FileUploadsDispatchContext } from '../Context';
import { useFileUploadEffects } from './useFileUploadEffects';

export const useUploadFileToS3 = () => {
  const dispatch = useContext(FileUploadsDispatchContext);
  const effects = useFileUploadEffects();

  const uploadFile = useCallback(async (file: File, fileUpload: WorkspaceFileUpload) => {

    try {
      const { credentials } = await effects.start({
        fileUploadId: fileUpload.id,
        workspaceId: fileUpload.data.workspaceId,
      });

      const client = new S3({
        credentials,
        region: 'us-east-1',
        useAccelerateEndpoint: true,
      });

      const upload = new Upload({
        client,
        params: {
          Body: file,
          Bucket: fileUpload.bucket,
          ContentType: fileUpload.file.type,
          Key: fileUpload.s3Key,
          Metadata: {
            'file-id': fileUpload.fileId.toString(),
            'group-id': fileUpload.data.groupId.toString(),
          },
          StorageClass: 'STANDARD_IA',
        },
      });

      upload.on('httpUploadProgress', event => {
        dispatch({
          fileIdentifier: fileUpload.identifier,
          type: 'update-upload-progress',
          value: Math.ceil((event.loaded * 100) / event.total),
        });
      });

      dispatch({
        fileIdentifier: fileUpload.identifier,
        type: 'register-s3-upload',
        upload,
      });

      const result = await upload.done();

      await effects.complete({
        fileUploadId: fileUpload.id,
        s3VersionId: (result as CompleteMultipartUploadOutput).VersionId,
        workspaceId: fileUpload.data.workspaceId,
      });

    } catch (e) {
      const error = e as Error;
      const aborted = error.message && error.message.includes('Request aborted by user');
      if (!aborted) {
        effects.failure({
          fileUploadId: fileUpload.id,
          workspaceId: fileUpload.data.workspaceId,
        });
      }
    }
  }, [
    dispatch,
    effects,
  ]);

  return uploadFile;
};

export default useUploadFileToS3;