import { List } from 'react-feather';
import { TableEmpty } from '@/components/presentation/Table.Empty';

export const EntitiesTableEmpty = () => {
  return (
    <TableEmpty
      Icon={() => <List size={120} />}
      message={copy.message}
      note={copy.note} />
  );
};

const copy = {
  message: `No entities available.`,
  note: `Import a series of entities to be boosted in interview transcripts or conduct interviews to see entities for the project.`,
  filtersActive: `Change your filters to see more entities.`,
};