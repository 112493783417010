import { cx } from '@/utils';
import type { ModalBaseProps } from './ModalBase';
import { ModalBase } from './ModalBase';
import styles from './style/Base.module.css';

type Props = {
  classes?: {
    root?: string;
    wrap?: string;
    close?: string;
  };
  className?: string;
  blocking?: boolean;
  hideCloseIcon?: boolean;
  wrapClassName?: string;
} & ModalBaseProps;

const Modal = ({ classes: classesParam = {}, disableEscapeClose = false, disableEventPropagation = false, disableOverlayClick = false, disablePortal = false, ...props }: ModalProps) => {
  const classes = {
    root: cx(props.className, classesParam.root),
    wrap: cx(props.wrapClassName, classesParam.wrap),
    close: cx(styles.close, classesParam.close),
  };

  return (
    <ModalBase
      classes={classes}
      disableEscapeClose={disableEscapeClose || props.blocking}
      disableOverlayClick={disableOverlayClick || props.blocking}
      disablePortal={disablePortal}
      disableEventPropagation={disableEventPropagation}
      onClose={props.onClose}
      open={props.open}>
      {!props.hideCloseIcon && !props.blocking &&
        <div className={styles.closeContainer}>
          <div
            className={classes.close}
            onClick={props.onClose}>
            ✕
          </div>
        </div>}
      {props.children}
    </ModalBase>
  );
};

export type ModalProps = Props;

export { Modal };
export default Modal;