import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as api from '@/api';
import { TabViewTableSkeleton } from '@/components/TabView';
import { FilesTable, FilesTableDataContext, FilesTableLoadingContext } from '@/screens/Workspace.FilesTable';
import { FolderStateContext } from './Context';
import styles from './style/Workspace.Folder.module.css';

const FolderTabFiles = () => {

  const loading = useContext(FilesTableLoadingContext);

  return (
    <div className={styles.tableWrap}>
      {loading
        ? <TabViewTableSkeleton />
        : (
          <div className={styles.root}>
            <div className={styles.wrap}>
              <FilesTable />
            </div>
          </div>
        )
      }
    </div>
  );
};

type Params = {
  folderId: string;
};

const WithState = () => {
  const { folder, object } = useContext(FolderStateContext);
  const params = useParams<Params>();

  const query = useQuery({
    queryKey: [
      `get:workspaces/objects`,
      object.workspaceId,
      object.id,
    ],
    queryFn: () => {
      return api.workspaces.fetchWorkspaceFiles({
        workspaceId: object.workspaceId,
        objectId: object.id,
      });
    },
  });

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <FilesTableDataContext.Provider value={query.data?.items || []}>
      <FilesTableLoadingContext.Provider value={query.isInitialLoading}>
        <FolderTabFiles />
      </FilesTableLoadingContext.Provider>
    </FilesTableDataContext.Provider>
  );
};

export { WithState as FolderTabFiles };
export default WithState;