import type { UseQueryOptions} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@/api';
import type * as Branding from '@/api/interfaces/groups.branding';
import * as QKey from '@/consts/querykey';
import { useSelectGroup } from './useSelectGroup';

export const useFetchBrandingQuery = (options: Options = {}) => {
  const group = useSelectGroup();

  const query = useQuery([QKey.Groups.Branding.Get, group.id], () => {
    return api.groups.branding.fetchBranding({
      groupId: group.id,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }, options);

  return query;
};

type Options = UseQueryOptions<Branding.FetchBranding.Response>;