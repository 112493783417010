import { useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Helmet } from 'react-helmet';
import { ProjectDetailsContext, ProjectStateLoadingContext } from './Context';
import styles from './style/ProjectHeader.module.css';

type Props = {
  children?: React.ReactNode;
};

export const Header = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          {/* <Breadcrumbs /> */}

          <div className={styles.details}>
            <div className={styles.info}>

              <div className={styles.header}>
                <Title />
              </div>

            </div>

            {props.children}
          </div>

        </div>
      </div>
    </div>
  );
};

const Title = (props: unknown) => {
  const isLoading = useContext(ProjectStateLoadingContext);
  const ctx = useContext(ProjectDetailsContext);

  if (isLoading) {
    return (
      <Skeleton
        style={{ marginRight: 10 }}
        height={30}
        width={400}
        variant="rectangular" />
    );
  }

  return (
    <>
      <Helmet title={ctx.query.data.project.name} />
      <h1 className={styles.title}>{ctx.query.data.project.name}</h1>
    </>
  );
};