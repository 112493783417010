import { compile } from 'html-to-text';
import { marked, type MarkedOptions } from 'marked';

const htmlToText = compile({
  wordwrap: false,
  selectors: [{ selector: 'table', format: 'dataTable' }],
});

function updateMarkdownCitations(value: string) {
  const regex = /\^([^^]+)\^/g;
  return value.replace(regex, (match, p1: string) => `[${p1}]`);
}

export function removeMarkdownCitations(value: string) {
  const regex = /\^{1,}([^^]+)\^/g;
  return value.replace(regex, '');
}

function markdownToHtml(markdown: string, options?: MarkedOptions): string {
  const unmarked = marked(markdown, options) as string;
  const trimmed = unmarked.trim();
  return trimmed;
}

export function markdownToRawText(markdown: string): string {
  const withCitationsUpdated = updateMarkdownCitations(markdown);
  const htmlText = markdownToHtml(withCitationsUpdated);
  const rawText = htmlToText(htmlText);
  return rawText;
}