import { memo } from 'react';
import { cx } from '@/utils';
import styles from './style/Base.module.css';

type Props = {
  children?: string | number;
  className?: string;
  text?: string;
};

const Header = memo(({
  text = '',
  ...props
}: Props) => {
  const className = cx(styles.header, props.className);
  return (
    <div className={className}>{props.children || text}</div>
  );
});

export { Header };
export default Header;