import { cx } from '@/utils';
import styles from './style/ColorInput.module.css';

type Props = {
  className?: string;
  value:      string;
};

export const Color = (props: Props) => {

  const value = props.value ?? '#000000';

  return (
    <div
      className={cx(styles.color, props.className)}
      style={{ backgroundColor: value }} />
  );
};

Color.displayName = 'ColorInput.Color';