import { ProjectContainer } from './Project.Container';
import { ProjectTabs } from './Project.Tabs';

type Props = unknown;

export const ProjectMain = (props: Props) => {

  return (
    <ProjectContainer>
      <ProjectTabs />
    </ProjectContainer>
  );
};

ProjectMain.displayName = 'Project.Main';