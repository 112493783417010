import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { startAsyncAudioSynthesis } from '@/api/audio-status';
import { useChatVoiceSource } from '@/store/selectors';
import { AudioPlayerContainer, useAudioPlayerRef, useBootstrapAudioPlayer } from '../AudioPlayer';
import type { TextToSpeechState } from './Context';
import {
  TextToSpeechStateContext,
  InitiateQuerySpeechToText,
  OnTextToSpeechAudioReadyContext,
  StopTextToSpeechContext,
} from './Context';

type Props = {
  children: React.ReactNode;
};

const defaultTTSState: TextToSpeechState = {
  kolSearchId: null,
  queryId: null,
  text: null,
  isPlaying: false,
};

export const TopLevelTTSContainer = (props: Props) => {
  const chatVoiceSource = useChatVoiceSource();
  const isPollyEnabled = chatVoiceSource === 'polly';

  if (isPollyEnabled) {
    return (
      <AudioPlayerContainer>
        <TextToSpeechAudioPlayerContainer {...props} />
      </AudioPlayerContainer>
    );
  }

  return (
    <AudioPlayerContainer>
      <BrowserTTSContainer {...props} />
    </AudioPlayerContainer>
  );
};

const BrowserTTSContainer = ({ children }: Props) => {
  const [pendingAudio, setPendingAudio] = useState<TextToSpeechState>(defaultTTSState);

  const stopTextToSpeech = useCallback(() => {
    speechSynthesis.cancel();
    setPendingAudio(defaultTTSState);
  }, []);

  const handleTextToSpeechInitiated = useCallback(async (data: TextToSpeechState) => {
    // TODO: add setting to fallback to browser TTS
    // Call browser TTS

    if (data.text && data.text.trim()) {
      const utterance = new SpeechSynthesisUtterance(data.text);
      utterance.lang = 'en-US';
      utterance.rate = 1;
      utterance.pitch = 1;
      speechSynthesis.speak(utterance);
    }
    setPendingAudio(data);
  }, []);

  return (
    <InitiateQuerySpeechToText.Provider value={handleTextToSpeechInitiated}>
      <StopTextToSpeechContext.Provider value={stopTextToSpeech}>
        <TextToSpeechStateContext.Provider value={pendingAudio}>
          {children}
        </TextToSpeechStateContext.Provider>
      </StopTextToSpeechContext.Provider>
    </InitiateQuerySpeechToText.Provider>
  );
};

const TextToSpeechAudioPlayerContainer = ({ children }: Props) => {
  const [playerRef, setPlayerRef] = useAudioPlayerRef();
  const [pendingAudio, setPendingAudio] = useState<TextToSpeechState>(defaultTTSState);
  const bootstrapAudioPlayer = useBootstrapAudioPlayer();

  const handleTextToSpeechAudioReady = useCallback((audioUrl: string) => {
    console.log('AudioStatusListener returned audio URL:', audioUrl);
    if (playerRef) {
      playerRef.src = audioUrl;
      // Mark as playing
      setPendingAudio(prev =>
        prev ? { ...prev, isPlaying: true } : defaultTTSState,
      );
      playerRef.play();

      const onEnded = () => {
        setPendingAudio(defaultTTSState);
        playerRef.removeEventListener('ended', onEnded);
      };

      playerRef.addEventListener('ended', onEnded);
    }
  }, [playerRef]);

  const audioMutation = useMutation({
    mutationFn: (data: TextToSpeechState) => {
      return startAsyncAudioSynthesis(data);
    },
    onMutate: async (data: TextToSpeechState) => {
      console.log('Async synthesis task started:');
      setPendingAudio(data);
    },
    onSuccess: (data) => {
      if (data.status === 'ready') {
        handleTextToSpeechAudioReady(data.audioUrl);
      }
    },
  });

  const handleTextToSpeechInitiated = useCallback(async (data: TextToSpeechState) => {
    return audioMutation.mutateAsync(data);
  }, [audioMutation]);

  const stopTextToSpeech = useCallback(() => {
    if (playerRef) {
      playerRef.pause();
      playerRef.currentTime = 0;
    }
    setPendingAudio(defaultTTSState);
  }, [playerRef]);

  return (
    <TextToSpeechStateContext.Provider value={pendingAudio}>
      <OnTextToSpeechAudioReadyContext.Provider value={handleTextToSpeechAudioReady}>
        <InitiateQuerySpeechToText.Provider value={handleTextToSpeechInitiated}>
          <StopTextToSpeechContext.Provider value={stopTextToSpeech}>
            {children}
            <audio {...bootstrapAudioPlayer()} ref={setPlayerRef} />
          </StopTextToSpeechContext.Provider>
        </InitiateQuerySpeechToText.Provider>
      </OnTextToSpeechAudioReadyContext.Provider>
    </TextToSpeechStateContext.Provider>
  );
};

export { TextToSpeechAudioPlayerContainer };
export default TextToSpeechAudioPlayerContainer;
