import type { Dispatch } from 'react';
import { createNamedContext } from '@/utils';
import type {
  AbortFileUpload,
  Effects,
  FileUploadState,
  FileUploadReview,
  ToggleUploadDisplay,
  UploadFiles,
  UploadFilesBase,
  UploadFilesForReview,
} from './interfaces';

export const FileUploadReviewStateContext = createNamedContext<FileUploadReview.State>(undefined, 'file-upload-review-state');
export const FileUploadReviewDispatchContext = createNamedContext<Dispatch<FileUploadReview.Action>>(undefined, 'file-upload-review-dispatch');

export const AbortFileUploadContext = createNamedContext<AbortFileUpload>(undefined, 'abort-file-upload');
export const FileUploadEffectsContext = createNamedContext<Effects.FileUploadEffects>(undefined, 'file-upload-effects');
export const FileUploadsStateContext = createNamedContext<FileUploadState.State>(undefined, 'file-uploads-state');
export const FileUploadsDispatchContext = createNamedContext<Dispatch<FileUploadState.Actions>>(undefined, 'file-uploads-dispatch');
export const ToggleUploadDisplayContext = createNamedContext<ToggleUploadDisplay>(undefined, 'toggle-upload-display');
export const CanUploadFilesContext = createNamedContext<boolean>(undefined, 'can-upload-files');

export const UploadFilesContext = createNamedContext<UploadFiles>(undefined, 'upload-files');
export const UploadFilesBaseContext = createNamedContext<UploadFilesBase>(undefined, 'upload-files-base');
export const InitiateFilesReviewContext = createNamedContext<UploadFilesForReview>(undefined, 'initiate-file-review');