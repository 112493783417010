import { Component, createRef } from 'react';
// import { CallType } from '@enums';
import type { ScheduledEventMetadata } from '@/types';
import * as utils from '@/utils';
import { AvailabilityEventPopover } from './EventPopover.Availability';
import type { FullCalEvent } from './interfaces';
import styles from './style/EventPopover.module.css';

type Options = {
  showActions?: boolean;
};

type State = {
  height: number;
  rect: DOMRect;
  options: Options;
};

type Props = {
  // cancelAdHocCall?: () => void;
  // cancelProjectCall?: () => void;
  dismiss: () => void;
  options?: Options;
  popover: {
    el: string;
    event: FullCalEvent;
  };
  remove: () => void;
  scrollable: Element;
};

class EventPopover extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      rect: this.getEventRect(),
      height: 0,
      options: {
        ...this.defaultOptions,
        ...props.options,
      },
    };
  }

  static compareFloats = (a: number, b: number) => {
    return (Number.isNaN(a) && Number.isNaN(b)) ||
           (a === b);
  };

  static compareRect = (a: DOMRect, b: DOMRect) => {
    if (!EventPopover.compareFloats(a.bottom, b.bottom)) return false;
    if (!EventPopover.compareFloats(a.height, b.height)) return false;
    if (!EventPopover.compareFloats(a.left, b.left)) return false;
    if (!EventPopover.compareFloats(a.right, b.right)) return false;
    if (!EventPopover.compareFloats(a.top, b.top)) return false;
    if (!EventPopover.compareFloats(a.width, b.width)) return false;
    if (!EventPopover.compareFloats(a.x, b.x)) return false;
    if (!EventPopover.compareFloats(a.y, b.y)) return false;
    return true;
  };

  static getEventMetadata = (event): ScheduledEventMetadata => {
    /* eslint-disable */
    // @ts-ignore
    if (!event || !event.extendedProps) {
      // @ts-ignore
      return {};
    }
    // @ts-ignore
    return event.extendedProps.metadata || {};
  };

  get defaultOptions() {
    return {
      showActions: true,
    };
  }

  hasScrolled: boolean;
  popoverEl = createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: Props, prevState: State) {
    // listen for resize
    if (!prevProps.popover && this.props.popover) {
      this.hasScrolled = false;
      window.addEventListener('resize', this.windowChanged);
      this.props.scrollable.addEventListener('scroll', this.windowScroll, true);
    }

    // stop listen for resize
    if (!this.props.popover) {
      window.removeEventListener('resize', this.windowChanged);
      this.props?.scrollable.removeEventListener('scroll', this.windowScroll, true);
    }

    // update height of popover after it renders
    if (this.popoverEl && this.popoverEl.current) {
      const height = this.popoverEl.current.clientHeight;
      if (prevState.height !== height) {
        this.setState({ height });
      }
    }

    // update rect of calendar event
    const rect = this.getEventRect();
    if (!EventPopover.compareRect(prevState.rect, rect)) {
      this.setState({
        rect: this.getEventRect(),
      });
    }

    if (prevProps.options !== this.props.options &&
        prevProps.options.showActions !== this.props.options.showActions) {
      this.setState({
        options: {
          ...this.defaultOptions,
          ...this.props.options,
        },
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowChanged);
    this.props?.scrollable?.removeEventListener('scroll', this.windowScroll, true);
  }

  getEventRect = (): DOMRect => {
    if (!this.props.popover) {
      // @ts-ignore
      return {};
    }

    const el = document.getElementById(this.props.popover.el);

    if (!el) {
      // @ts-ignore
      return {};
    }

    return el.getBoundingClientRect();
  };

  getRootInline = () => {
    const WIDTH = utils.device.phone ? 220 : 275;
    const bodyRect = document.body.getBoundingClientRect();
    const elRect = this.state.rect;
    const bodyWidth = bodyRect.width;
    const bodyTop = bodyRect.top;
    const rectTop = elRect.top || 0;
    const rectRight = elRect.right || 0;
    const rectLeft = elRect.left || 0;
    const rectX = elRect.x || 0;

    const positionLeft = (bodyWidth / 2) > rectX;

    const x = positionLeft
      ? rectLeft - 15
      : (rectRight - WIDTH) - 15;
    const y = rectTop - bodyTop + 45;

    return {
      className: positionLeft ? styles.left : styles.right,
      style: {
        left: x,
        top: y || 0,
        width: WIDTH,
      },
    };
  };

  windowChanged = () => {
    this.props.dismiss();
  };

  windowScroll = () => {
    if (!this.hasScrolled) {
      this.hasScrolled = true;
    } else {
      // this.props.dismiss();
    }
  };

  renderBody = () => {
    const event = this.props.popover.event;

    return (
      <AvailabilityEventPopover
        timeStart={event.start}
        timeEnd={event.end}
        onDelete={this.props.remove} />
    );
  };

  render() {
    const { className, style } = this.getRootInline();

    return (
      <div
        className={className}
        ref={this.popoverEl}
        style={style}>
        {this.renderBody()}
      </div>
    );
  }
}

export default EventPopover;