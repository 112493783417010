import { z } from 'zod';

export enum ChatActionType {
  ReaskWithHcp = 'reask-with-hcp',
}
export const ChatActionTypeModel = z.nativeEnum(ChatActionType);

export const ReaskWithHcpModel = z.object({
  type: z.literal(ChatActionType.ReaskWithHcp),
  payload: z.object({
    hcpId: z.number(),
    queryId: z.number(),
  }),
});
export type ReaskWithHcpChatAction = z.infer<typeof ReaskWithHcpModel>;

export type ChatAction<T extends ChatActionType = ChatActionType> = {
  [ChatActionType.ReaskWithHcp]: ReaskWithHcpChatAction;
}[T];