import { useMemo } from 'react';
import { ColorPaletteType } from '@/enums';
import { BrandThemingContext } from './Context';
import { useFetchBrandingQuery } from './hooks/branding.fetch';
import type { CategoryGroup } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const BrandingContainer = (props: Props) => {
  const query = useFetchBrandingQuery({
    refetchOnWindowFocus: false,
  });

  const categories = useMemo(() => {
    if (!query.data) return [];

    return query.data.theming.themes.reduce((acc, x) => {
      const items = query.data.theming.palettes
        .filter(p => p.categoryId === x.categoryId)
        .map(p => ({
          colors: p.data.colors,
          editable: p.typeId !== ColorPaletteType.Preset,
          id: p.id,
          name: p.name,
        }));

      return acc.concat({
        category: {
          id: x.categoryId,
          name: lookup[x.categoryId],
        },
        items,
        paletteId: x.paletteId,
      });
    }, [] as CategoryGroup[]);
  }, [query.data]);

  const context = useMemo(() => ({
    data: {
      categories,
    },
    query,
  }), [categories, query]);

  return (
    <BrandThemingContext.Provider value={context}>
      {props.children}
    </BrandThemingContext.Provider>
  );
};

BrandingContainer.displayName = 'Branding.Container';

const lookup = {
  1: 'Theme 1',
  2: 'Theme 2',
  3: 'Theme 3',
};