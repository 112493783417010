/** @jsxImportSource @emotion/react */
import type { MouseEvent } from 'react';
import { useEffect, useMemo, useContext, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { useFloating, useInteractions, useDismiss, autoUpdate } from '@floating-ui/react';
import { createId } from '@paralleldrive/cuid2';
import { ArrowDown, ArrowUp, ExternalLink, Filter } from 'react-feather';
import { TextQueryHint } from '@/components/presentation/Message.QueryHint';
import { TablePagination } from '@/components/presentation/TablePagination';
import { Tooltip } from '@/components/presentation/Tooltip';
import { isDemoEnv } from '@/config/utils';
import { useTablePagination } from '@/hooks/useTablePagination';
import type { KolSearchResult, KolSearchRecommendation } from '@/types/kol';
import { buildKolMailTo } from '@/utils';
import { createOneTapLink, formatPhoneNumber } from '@/utils/phone';
import { KolSearchResultsSortContext, KolSearchResultsFilterContext } from './context';
import type { SortFnMap } from './hooks';
import { useFilteredItems, useSortedItems, useTableFilterState, useTableSortState } from './hooks';
import type { KolSearchResultSortKey, StringAccessorFnMap } from './interfaces';
import { KolAddress } from './KolSearch.Address';
import { KolBadgeTooltip } from './KolSearch.KolBadge';
import { KolRecommendationTooltip } from './KolSearch.Recommended';
import { FilterPanel } from './KolSearch.Table.Filters';
import { areFiltersSimilar, isFilterValid } from './utils';

type Props = {
  className?: string;
  onSelectProfile: (kol: Pick<KolSearchRecommendation, 'id' | 'name'>) => void;
  selectionDisabled?: boolean;
  records: KolSearchRecommendation[];
  pageSize?: number;
};

export const KolResultsTable = ({ className, onSelectProfile, selectionDisabled, records: recordsParam, pageSize = 5 }: Props) => {
  const { setTotal, ...pagination } = useTablePagination({
    index: 0,
    pageSize,
    total: recordsParam?.length,
  });

  const [filterState, dispatchFilterState] = useTableFilterState();
  const filteredRecords = useFilteredItems({ items: recordsParam, accessorFnMap, filters: filterState });
  const [filtersOpen, setFiltersOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: filtersOpen,
    onOpenChange: setFiltersOpen,
    placement: 'top-start',
    whileElementsMounted: autoUpdate,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  const sortState = useTableSortState<SortKey>();
  const records = useSortedItems({ sortBy: sortState.sortBy, direction: sortState.sortDir, sortFns, items: filteredRecords });

  const hasActiveFilters = useMemo(() => filterState.some(f => isFilterValid(f)), [filterState]);

  useEffect(() => {
    setTotal(records?.length);
  }, [
    setTotal,
    records?.length,
  ]);

  const page = useMemo(() => {
    const start = pagination.index * pagination.size;
    const end = start + pagination.size;

    const items = (records || []);
    return items.slice(start, end);
  }, [
    records,
    pagination.index,
    pagination.size,
  ]);

  return (
    <KolSearchResultsFilterContext.Provider value={{ setOpen: setFiltersOpen, state: filterState, dispatch: dispatchFilterState }}>
      <KolSearchResultsSortContext.Provider value={sortState}>
        <Container className={className}>
          <ScrollContainer>
            <HeaderContainer ref={refs.setReference} {...getReferenceProps()}>
              <NameAttributeContainer>
                <HeaderAttribute disabled={selectionDisabled} sortKey='name'>Name</HeaderAttribute>
              </NameAttributeContainer>
              <ViewProfileAttributeContainer>
                <Attribute>Profile</Attribute>
              </ViewProfileAttributeContainer>
              <AffiliationAttributeContainer>
                <HeaderAttribute disabled={selectionDisabled} sortKey='affiliation'>Primary Affiliation</HeaderAttribute>
              </AffiliationAttributeContainer>
              <AddressAttributeContainer>
                <HeaderAttribute disabled={selectionDisabled} sortKey='location'>Location</HeaderAttribute>
              </AddressAttributeContainer>
              <EmailAttributeContainer>
                <HeaderAttribute disabled={selectionDisabled} sortKey='email'>Email</HeaderAttribute>
              </EmailAttributeContainer>
              <PhoneAttributeContainer>
                <HeaderAttribute disabled={selectionDisabled} sortKey='phone'>Phone</HeaderAttribute>
              </PhoneAttributeContainer>
            </HeaderContainer>
            {!records.length && hasActiveFilters &&
              <NoResultsContainer>
                No results match your filters. Please try adjusting your filters to see more results.
              </NoResultsContainer>}
            {page.map((result, index) => {
              const primaryAfilliation = result.affiliations[0];
              return (
                <ItemContainer key={index}>
                  <NameAttributeContainer>
                    <NameAttribute>
                      {result.name}
                      <Badges>
                        {result.isKol && <KolBadgeTooltip />}
                        {!!result.recommendations?.length && !isDemoEnv() && <KolRecommendationTooltip values={result.recommendations} />}
                      </Badges>
                    </NameAttribute>
                  </NameAttributeContainer>
                  <ViewProfileAttributeContainer>
                    <ViewProfileAttribute>
                      <StyledViewProfileButton
                        disabled={selectionDisabled}
                        onClick={() => onSelectProfile(result)}
                        value="View" />
                    </ViewProfileAttribute>
                  </ViewProfileAttributeContainer>
                  <AffiliationAttributeContainer>
                    <AffiliationAttribute>
                      {primaryAfilliation ? (
                        <Tooltip
                          title={primaryAfilliation.name}
                          enterDelay={1000}
                          enterNextDelay={500}>
                          <div css={{ overflow: 'hidden' }}>{primaryAfilliation.name}</div>
                        </Tooltip>
                      ) : (
                        <PlaceholderText>None</PlaceholderText>
                      )}
                    </AffiliationAttribute>
                  </AffiliationAttributeContainer>
                  <AddressAttributeContainer>
                    <AddressAttribute>
                      {primaryAfilliation ? (
                        <KolAddress
                          city={primaryAfilliation.city}
                          state={primaryAfilliation.state}
                          zip={primaryAfilliation.zip}
                          country={primaryAfilliation.country}
                          street={primaryAfilliation.street} />
                      ) : (
                        <KolAddress
                          city={result.city}
                          state={result.state}
                          zip={result.zipCode}
                          country={result.country}
                          street={result.streetAddress} />
                      )}
                    </AddressAttribute>
                  </AddressAttributeContainer>
                  <EmailAttributeContainer>
                    <EmailAttribute>
                      {result.email ? (
                        <Tooltip
                          title={result.email}
                          enterDelay={1000}
                          enterNextDelay={500}>
                          <EmailMailTo
                            href={buildKolMailTo({ email: result.email })}
                            target="_blank"
                            rel="noreferrer">
                            <EmailLinkText>{result.email}</EmailLinkText>
                            <StyledLinkIcon size={14} />
                          </EmailMailTo>
                        </Tooltip>
                      ) : (
                        <PlaceholderText>None</PlaceholderText>
                      )}
                    </EmailAttribute>
                  </EmailAttributeContainer>
                  <PhoneAttributeContainer>
                    <Attribute>
                      {result.phone ? (
                        <EmailMailTo
                          href={createOneTapLink(result.phone)}
                          target="_blank"
                          rel="noreferrer">
                          {formatPhoneNumber(result.phone)}
                        </EmailMailTo>
                      ) : (
                        <PlaceholderText>None</PlaceholderText>
                      )}
                    </Attribute>
                  </PhoneAttributeContainer>
                </ItemContainer>
              );
            })}
          </ScrollContainer>
          {pagination.total > pageSize && (
            <Footer>
              <TablePagination
                canNextPage={!selectionDisabled && pagination.canNextPage}
                canPreviousPage={!selectionDisabled && pagination.canPreviousPage}
                nextPage={pagination.nextPage}
                previousPage={pagination.previousPage}
                pageIndex={pagination.index}
                pageSize={pagination.size}
                totalCount={pagination.total} />
            </Footer>
          )}
        </Container>
        {filtersOpen &&
          <FilterPanelContainer ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <FilterPanel />
          </FilterPanelContainer>}
      </KolSearchResultsSortContext.Provider>
    </KolSearchResultsFilterContext.Provider>
  );
};

const Badges = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
});

const Container = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.gray.light1}`,
  borderRadius: 4,
  margin: 0,
  padding: 0,
}));

const ScrollContainer = styled.div({
  overflowX: 'auto',
});

const ItemContainer = styled.div({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '150px 95px 190px 190px 175px 150px',
});

const HeaderContainer = styled(ItemContainer)(({ theme }) => ({
  [`& > *`]: {
    backgroundColor: theme.palette.gray.light2,
    fontFamily: theme.fonts.semiBold,
    fontSize: 14,
  },
}));

const AttributeContainer = styled.div({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(var(--column-width-min), 1fr))',
});

const Attribute = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  borderRight: `1px solid ${theme.palette.gray.light2}`,
  borderBottom: `1px solid ${theme.palette.gray.light2}`,
  padding: 8,
  fontSize: 14,
  overflow: 'hidden',
  height: '100%',
}));

const StyledLinkIcon = styled(ExternalLink)(({ theme }) => ({
  color: theme.palette.hyperlink,
  transition: 'all 150ms',
  flexShrink: 0,
  marginLeft: 4,
}));

const NameAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 150,
});

const AffiliationAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 190,
});

const AddressAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 190,
});

const EmailAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 175,
});

const PhoneAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 150,
});

const ViewProfileAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: 85,
});

const ViewProfileAttribute = styled(Attribute)({
  display: 'flex',
  justifyContent: 'center',
});

const StyledViewProfileButton = styled(TextQueryHint)({
  minWidth: 75,
  maxWidth: 75,
});

const NameAttribute = styled(Attribute)({

});

const AffiliationAttribute = styled(Attribute)({
  display: 'flex',
  overflow: 'hidden',
  // height: '3.7em',
});

const AddressAttribute = styled(Attribute)({
  display: 'flex',
  alignItems: 'center',
  transition: 'all 150ms',
  // height: '3.7em',
});

const EmailAttribute = styled(Attribute)({
  display: 'flex',
  alignItems: 'center',
  transition: 'all 150ms',
  // height: '3.7em',
});

const SortableAttribute = styled(Attribute)({
  cursor: 'pointer',
  display: 'flex',
  gap: 5,
  userSelect: 'none',
});

const EmailMailTo = styled.a(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  transition: 'all 150ms',
  overflow: 'hidden',
  height: '100%',

  ':hover': {
    color: theme.palette.hyperlink,
  },

  [`> ${String(StyledLinkIcon)}`]: {
    opacity: 0,
  },

  [`:hover > ${String(StyledLinkIcon)}`]: {
    opacity: 1,
  },
}));

const EmailLinkText = styled.div({
  height: '100%',
  wordBreak: 'break-all',
});

const Footer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: 4,
});

const PlaceholderText = styled.div(({ theme }) => ({
  color: theme.palette.gray.main,
}));

const FilterPanelContainer = styled.div({
  padding: 10,
  backgroundColor: 'white',
  transition: `box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)`,
  borderRadius: 4,
  boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
  //width: 500,
});

const NoResultsContainer = styled.div({
  width: '100%',
  textAlign: 'center',
  padding: 20,
  fontStyle: 'italic',
});

const SortIcon = (props: { sortDir: 'asc' | 'desc' }) => {
  if (props.sortDir === 'asc') {
    return <ArrowUp size={16} />;
  } else if (props.sortDir === 'desc') {
    return <ArrowDown size={16} />;
  } else {
    return null;
  }
};

const HeaderAttribute = (props: HeaderAttributeProps) => {
  const sortCtx = useContext(KolSearchResultsSortContext);
  const { setOpen, state, dispatch } = useContext(KolSearchResultsFilterContext);

  const hasActiveFilter = state.some(f => isFilterValid(f) && areFiltersSimilar(props.sortKey, f.key));

  const onFilterClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!state.some(f => f.key === props.sortKey)) {
      dispatch({
        type: 'filters-set',
        payload: [{ key: props.sortKey, identifier: createId(), type: null, value: null }],
      });
    }
    setOpen(old => !old);
  }, [dispatch, props.sortKey, setOpen, state]);

  if (props.disabled) {
    return <Attribute>{props.children}</Attribute>;
  }

  return (
    <SortableAttribute onClick={() => sortCtx.toggleSortKey(props.sortKey)}>
      {props.children}
      <div style={{ flexGrow: 1 }} />
      {sortCtx.sortBy === props.sortKey && <SortIcon sortDir={sortCtx.sortDir} />}
      <Filter size={18} fill={hasActiveFilter ? 'black' : 'none'} onClick={onFilterClick} />
    </SortableAttribute>
  );
};

type SortKey = KolSearchResultSortKey;

type HeaderAttributeProps = {
  sortKey: SortKey;
  disabled: boolean;
} & ChildrenProps;

const sortFns: SortFnMap<KolSearchResult, SortKey> = {
  email: (a, b) => a.email?.localeCompare(b.email),
  name: (a, b) => a.name?.localeCompare(b.name),
  firstName: (a, b) => a.firstName?.localeCompare(b.firstName),
  lastName: (a, b) => a.lastName?.localeCompare(b.lastName),
  phone: (a, b) => a.phone?.localeCompare(b.phone),
  location: (a, b) => a.streetAddress?.localeCompare(b.streetAddress),
  affiliation: (a, b) => a.affiliations[0]?.name?.localeCompare(b.affiliations[0]?.name),
};

const accessorFnMap: StringAccessorFnMap<KolSearchResult, SortKey> = {
  email: x => x.email,
  name: x => x.name,
  firstName: x => x.firstName,
  lastName: x => x.lastName,
  phone: x => x.phone,
  location: x => [x.streetAddress, x.city, x.state, x.country, x.zipCode].filter(Boolean).join(' '),
  affiliation: x => x.affiliations[0]?.name,
};