import { useMemo, useContext } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { X } from 'react-feather';
import type { Column, CellProps, Row } from 'react-table';
import { useTable, useFlexLayout } from 'react-table';
import type { FetchProjectTerms } from '@/api/interfaces/projects';
import * as Table from '@/components/Table';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { TermFilterContext, TranscriptReplacementsContext, SelectedTermsContext } from '@/containers/Project.Terms';
import { cx } from '@/utils';
import { BoostOverridesContext } from './Context';
import styles from './style/TermsTable.module.css';
import { EntitiesTableEmpty } from './Table.Empty';

type Term = FetchProjectTerms.Response['terms'][number];

type Props = {
  terms: Term[];
  collapsed: boolean;
};

export const TermsTable = (props: Props) => {
  const { highlightTerm } = useContext(TermFilterContext);
  const columns = useMemo<Column<Term>[]>(() => (
    [{
      id: 'checkbox',
      Header: '',
      Cell: CheckboxCell,
      width: 40,
      minWidth: 40,
    }, {
      id: 'word',
      Header: 'Word',
      Cell: WordCell,
    }, {
      id: 'occurrences',
      Header: 'Occurrences',
      Cell: OccurrencesCell,
    }, !props.collapsed ? {
      id: 'transcripts',
      Header: '# of Transcripts',
      Cell: TranscriptsCell,
    } : null, !props.collapsed ? {
      id: 'boosted',
      Header: 'Boosted',
      Cell: BoostCell,
      width: 60,
      minWidth: 60,
    } : null].filter(Boolean)
  ), [props.collapsed]);

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<Term>({
    columns,
    data: props.terms,
  }, useFlexLayout);

  return (
    <Table.Root
      EmptyComponent={EntitiesTableEmpty}
      empty={!props.terms.length}
      loading={false}>
      <Table.Header headerGroups={headerGroups} classes={{ tr: styles.headTr }} />
      <Table.Body
        {...getTableBodyProps()}
        classes={{
          tbody: styles.body,
          td: styles.td,
          tr: styles.tr,
        }}
        getRowClass={(row: Row<Term>) => row.original.termValue === highlightTerm ? styles.activeRow : null}
        prepareRow={prepareRow}
        rows={rows} />
    </Table.Root>
  );
};

const WordCell = (props: CellProps<Term>) => {
  const term = props.row.original;
  const { confirmedTerms } = useContext(TranscriptReplacementsContext);
  const isDerived = useIsTermDerived(term);

  const isTypo = term.term?.type === 'spell-check' && !confirmedTerms.some(t => t.term === term.termValue);

  return (
    <div className={styles.termCell}>
      <span className={cx(isTypo ? styles.typo : null, isDerived ? styles.derived : null)}>{term.termValue}</span>
      <div className={styles.hoverShow}>
        {/*         <CorrectionHover term={term} /> */}
      </div>
    </div>
  );
};

const OccurrencesCell = (props: CellProps<Term>) => {
  const { setHighlightTerm } = useContext(TermFilterContext);
  const term = props.row.original;
  const active = useIsTermActive(term);
  const isDerived = useIsTermDerived(term);
  return (
    <div className={cx(styles.occurrencesCell, active ? styles.activeCell : null)}>
      <div>{isDerived ? '-' : term.occurrences}</div>
      {/* {active ?
        <div className={styles.clearActive} onClick={() => setHighlightTerm('')}>
          <X size={16} />
        </div> :
        <div className={cx(styles.viewOccurrences, styles.hoverShow)} onClick={() => setHighlightTerm(term.termValue)}>View</div>
      } */}
    </div>
  );
};

const TranscriptsCell = (props: CellProps<Term>) => {
  const term = props.row.original;
  const isDerived = useIsTermDerived(term);

  if (isDerived) return '-';

  return (
    <div>{props.row.original.transcriptIds.length}</div>
  );
};

const CheckboxCell = (props: CellProps<Term>) => {
  const { selectedTermValues, toggleTermValue } = useContext(SelectedTermsContext);
  const isDerived = useIsTermDerived(props.row.original);

  if (isDerived) return null;
  return (
    <Checkbox checked={selectedTermValues.includes(props.row.original.termValue)} onClick={() => toggleTermValue(props.row.original.termValue)} />
  );
};

const BoostCell = (props: CellProps<Term>) => {
  const { projectId } = useContext(TranscriptReplacementsContext);
  const { setTermBoost } = useContext(BoostOverridesContext);
  const isDerived = useIsTermDerived(props.row.original);
  /*
  const updateEntityBoostMutation = useMutation({
    mutationFn: (boosted: boolean) => {
      if (props.row.original.entities[0]) {
        const entity = props.row.original.entities[0];
        return updateEntityBoost({
          boosted,
          projectId,
          type: entity.type,
          name: entity.name,
        });
      } else {
        return addItems({
          projectId,
          items: [{ type: 'unknown', name: props.row.original.termValue, boosted }],
        });
      }
    }, onMutate: boosted => {
      setTermBoost(props.row.original.termValue, boosted);
    }, onError: () => {
      Toast.error({
        title: 'There was an error updating the boost status',
      });
    },
  });
  */
  const { palette, theme } = useContext(AppThemingContext);

  const checkedColor = theme?.enabled
    ? palette.primary.main
    : `var(--pri-02)`;

  if (isDerived) return null;

  return (
    <Checkbox
      disableRipple={true}
      color="default"
      sx={{
        color: 'var(--gray-d)',
        padding: 0,
        marginRight: '6px',
        flexShrink: 0,
        [`&.Mui-checked`]: {
          color: checkedColor,
        },
      }}
      checked={props.row.original.boosted ?? false}
      onChange={e => setTermBoost(props.row.original.termValue, e.target.checked)} />
  );
};

const useIsTermDerived = (term: Term) => {
  return !term.entities?.length && !term.term?.id;
};

export const useIsTermActive = (props: { termValue: string }) => {
  const { highlightTerm } = useContext(TermFilterContext);

  return highlightTerm === props?.termValue;
};