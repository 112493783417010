import { useCallback, useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { ThemeGroupSelectContext } from './Context';
import styles from './style/Select.module.css';

type Props = {
  onClick: () => void;
};

export const CreateTheme = ({ onClick, ...props }: Props) => {
  const select = useContext(ThemeGroupSelectContext);

  const handleClick = useCallback(() => {
    onClick();
    select.close();
  }, [
    onClick,
    select,
  ]);

  return (
    <MenuItem
      classes={{ root: styles.create }}
      disableRipple
      onClick={handleClick}>
      <div className={styles.item}>
        <div className={styles.text}>+ Create Theme</div>
      </div>
    </MenuItem>
  );
};

CreateTheme.displayName = 'ColorPalette.Select.CreateTheme';