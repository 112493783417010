import type { Dispatch } from 'react';
import { useContext } from 'react';
import { createNamedContext } from '@/utils';

type TextToSpeechAudioPlayerRefValue = [HTMLAudioElement | undefined, Dispatch<HTMLAudioElement | undefined>];

type InitiateQuerySpeechToText = (data: TextToSpeechState) => unknown;
export type TextToSpeechState = {
  kolSearchId: number | null;
  queryId: number | null;
  text: string | null;
  isPlaying: boolean;
  // status: string; // TODO: add
};

export const TextToSpeechAudioPlayerRefContext = createNamedContext<TextToSpeechAudioPlayerRefValue>(
  undefined,
  'TextToSpeechAudioPlayer.Context',
);

export const OnTextToSpeechAudioReadyContext = createNamedContext<(audioUrl: string) => void>(null, 'OnTextToSpeechAudioReady');
export const InitiateQuerySpeechToText = createNamedContext<InitiateQuerySpeechToText>(null, 'InitiateQuerySpeechToText');
export const TextToSpeechStateContext = createNamedContext<TextToSpeechState>(null, 'TextToSpeechState');
export const StopTextToSpeechContext = createNamedContext<() => void>(null, 'StopTextToSpeech');
export const useTextToSpeechAudioPlayerRef = () => useContext(TextToSpeechAudioPlayerRefContext);