import type { SyntheticEvent } from 'react';
import { useCallback } from 'react';
import { useAudioPlayerState } from '../Context';

export const useBootstrapAudioPlayer = () => {

  type AudioEvent = SyntheticEvent<HTMLAudioElement, Event>;

  const [_, dispatch] = useAudioPlayerState();

  const onLoadStart = useCallback((event: AudioEvent) => {
    dispatch({
      type: 'load-start',
    });
  }, [dispatch]);

  const onLoadedMetadata = useCallback((event: AudioEvent) => {
    dispatch({
      type: 'load-end',
    });
  }, [dispatch]);

  const onDurationChange = useCallback((event: AudioEvent) => {
    dispatch({
      duration: (event.target as HTMLAudioElement).duration,
      type: 'duration-change',
    });
  }, [dispatch]);

  const onPlay = useCallback((event: AudioEvent) => {
    dispatch({
      type: 'play',
    });
  }, [dispatch]);

  const onPause = useCallback((event: AudioEvent) => {
    dispatch({
      type: 'pause',
    });
  }, [dispatch]);

  const onEnded = useCallback((event: AudioEvent) => {
    dispatch({
      type: 'end',
    });
  }, [dispatch]);

  const onError = useCallback((event: AudioEvent) => {
    dispatch({
      error: (event.target as HTMLAudioElement).error,
      type: 'load-error',
    });
  }, [dispatch]);

  const onTimeUpdate = useCallback((event: AudioEvent) => {
    dispatch({
      time: (event.target as HTMLAudioElement).currentTime,
      type: 'time-change',
    });
  }, [dispatch]);

  const bootstrap = () => ({
    onDurationChange,
    onEnded,
    onError,
    onLoadStart,
    onLoadedMetadata,
    onPause,
    onPlay,
    onTimeUpdate,
  });

  return bootstrap;

};