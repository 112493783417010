import type { FileUploadFile } from '@/types';
import FileUploadBase from './FileUploadBase';
import styles from './style/FileUpload.module.css';

type InvalidFileProps = {
  file: FileUploadFile;
  reason: string;
};

export const InvalidFile = ({ file, reason }: InvalidFileProps) => {

  return (
    <FileUploadBase
      file={file}
      subtitle={<span className={styles.invalid}>{reason}</span>} />
  );
};

export default InvalidFile;