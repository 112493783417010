import { useCallback, useContext, useMemo } from 'react';
import { createId } from '@paralleldrive/cuid2';
import { ChatFileUploadStatus } from '@/enums/chat';
import {
  SubmitMessageContext,
  ChatSessionInputContext,
  ChatSessionFileUploadsContext,
  IsPreparingUploadContext,
  ChatHasAccessContext,
} from './context';
import { useSelectedQueryParentIdentifier, useSubmitQueryMutation } from './hooks';

type Props = {
  children: React.ReactNode;
};

export const SubmitMessageContainer = (props: Props) => {
  const input = useContext(ChatSessionInputContext);
  const fileUploads = useContext(ChatSessionFileUploadsContext);
  const getSelectedQueryParentIdentifier = useSelectedQueryParentIdentifier();
  const hasAccess = useContext(ChatHasAccessContext);

  const { isLoading, mutateAsync } = useSubmitQueryMutation();
  const isPreparingUpload = useContext(IsPreparingUploadContext);

  const handleSubmit = useCallback(() => {
    const identifier = createId();
    const parentQueryIdentifier = getSelectedQueryParentIdentifier();

    const query = input;

    return mutateAsync({
      action: null,
      identifier,
      parentQueryIdentifier,
      query,
    });
  }, [
    getSelectedQueryParentIdentifier,
    input,
    mutateAsync,
  ]);

  const canSubmit = useMemo(() => {
    return input.trim().length > 0 &&
      hasAccess &&
      !isLoading && !isPreparingUpload &&
      !fileUploads.some(s => s.items.some(i => i.status !== ChatFileUploadStatus.Ready));
  }, [fileUploads, hasAccess, input, isLoading, isPreparingUpload]);

  const submitCtx = useMemo(() => ({
    canSubmit,
    isSubmitting: isLoading,
    onSubmit: handleSubmit,
  }), [canSubmit, isLoading, handleSubmit]);

  return (
    <SubmitMessageContext.Provider value={submitCtx}>
      {props.children}
    </SubmitMessageContext.Provider>
  );
};