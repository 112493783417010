import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { EventApi, View } from '@fullcalendar/core';
import { Calendar as FullCalendar } from '@fullcalendar/core';
import { addHours, isAfter, isSameDay, addSeconds, endOfDay, getMinutes, startOfMinute } from 'date-fns';
import { CalendarPicker, CalendarPickerHeader, type CalendarPickerHeaderParams } from '@/components/CalendarPicker';
import { Button } from '@/components/presentation/Button';
import { CalendarDateEvent } from '@/types';
import EventPopover from './EventPopover';
import { getEventAvailabilityId, getAvailableEvents, getNewEvents } from './events';
import { useInjectedStyles } from './hooks';
import type { FullCalEvent } from './interfaces';
import styles from './style/Viewing.module.css';
import { createId } from './transformers';
import { getProvidingDefaults } from './utils';

export const Viewing = (props: unknown) => {
  const [events, setEvents] = useState<FullCalEvent[]>([]);
  const [removedEvents, setRemovedEvents] = useState<FullCalEvent[]>([]);
  const [popover, setPopover] = useState<PopoverState | null>(null);

  const handleEventClick = useCallback((info: EventClickParams) => {
    setPopover({
      el: info.el.id,
      event: info.event,
    });
  }, []);

  const handleSelect = useCallback((info: SelectParams) => {
    setEvents(events => {
      return [...events, {
        end: isSameDay(info.end, info.start) ? info.end : addSeconds(endOfDay(info.start), 1),
        id: createId(),
        start: info.start,
        editable: true,
        startEditable: true,
        title: 'Available',
        extendedProps: {
          isNew: true,
          canRemove: true,
          type: 'available',
          isBusy: false,
          metadata: {
            availabilityId: null,
          },
        },
      }];
    });
  }, []);

  const removeEventAvailability = useCallback(() => {
    const event = popover.event;

    if (event.extendedProps.canRemove) {
      const availId = getEventAvailabilityId(event);

      if (availId) {
        const toRemove = events.filter(e => getEventAvailabilityId(e) == availId);

        setEvents(events.filter(e => !toRemove.map(m => m.id).includes(e.id)));
        setRemovedEvents(removedEvents.concat(toRemove));

      } else {
        setEvents(events.filter(e => e.id != event.id));
      }
    }
    setPopover(null);
  }, []);

  return (
    <Calendar
      dismissPopver={() => setPopover(null)}
      events={events}
      onEventClick={handleEventClick}
      popover={popover}
      removedEvents={removedEvents}
      removeEventAvailability={removeEventAvailability}
      select={handleSelect} />
  );
};

type Props = {
  // id: string;
  dismissPopver: () => void;
  events: FullCalEvent[];
  removedEvents: FullCalEvent[];
  select: (info: SelectParams) => void;
  onEventClick: (info: EventClickParams) => boolean | void;
  popover: PopoverState | null;
  removeEventAvailability: () => void;
};
// onClickContainer: (e: React.MouseEvent<HTMLDivElement>) => void;
// } & Pick<FullCalendar, 'gotoDate'>;

const Calendar = (props: Props) => {
  const calRef = useRef<FullCalendar | null>(null);
  const scrollable = useRef<Element | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const newEvents = getNewEvents(props.events);
  const availableEvents = getAvailableEvents(props.events);
  const dates = availableEvents.map(x => x.start);

  const canSubmit = newEvents.length
    || props.removedEvents.length;

  useInjectedStyles();

  const renderCalendar = useCallback((calendar: FullCalendar) => {
    calendar.render();
    const nowLine = document.getElementsByClassName('fc-now-indicator fc-now-indicator-line');

    if (nowLine && nowLine.length) {
      setTimeout(() => {
        nowLine[0]?.scrollIntoView({
          block: 'center',
        });
      }, 0);
    }
  }, []);

  const getScrollableElement = useCallback(() => {
    const elements = document.getElementsByClassName('fc-scroller fc-time-grid-container');
    return elements.length ? elements[0] : null;
  }, []);

  useEffect(() => {

    if (ref.current) {
      if (!calRef.current) {
        const cal = new FullCalendar(document.getElementById(styles.calendar), {
          ...getProvidingDefaults(),
          customButtons: {
            save: {
              text: 'Save',
              click: () => {},
            },
          },
          eventClick: props.onEventClick,
          eventSources: [
            { id: 'events', events: (_, cb) => cb(props.events) },
          ],
          select: props.select,
        });

        calRef.current = cal;
        renderCalendar(cal);
        scrollable.current = getScrollableElement();
      }
    }

  }, [renderCalendar]);

  const renderCustomHeader = useCallback((params: CalendarPickerHeaderParams) => {
    return (
      <CalendarPickerHeader
        className={styles.picker}
        {...params} />
    );
  }, []);

  return (
    <div
      className={styles.root}
      onClick={() => {
        if (props.popover) {
          props.dismissPopver();
        }
      }}>

      <div className={styles.wrap}>
        <div className={styles.sidebar}>
          <div className={styles.gutter}>
            <CalendarPicker
              marked={dates}
              onChange={calRef.current?.gotoDate}
              renderCustomHeader={renderCustomHeader} />

            {/* <ExternalCalendarSelector
              calendar={props.calendar}
              onChange={props.onChangeExternalCalendar}
              user={user} /> */}
          </div>
        </div>
        <div className={styles.main}>
          <div
            id={styles.calendar}
            className={styles.calendar}
            ref={ref} />
          {/* <TimeZone /> */}
        </div>
      </div>
      {props.popover &&
        <EventPopover
          dismiss={props.dismissPopver}
          popover={props.popover}
          remove={props.removeEventAvailability}
          scrollable={scrollable.current} />}
    </div>
  );
};

Viewing.displayName = 'Viewing';

const useCalendar = () => {
  const calendar = useMemo(() => {
    const cal = new FullCalendar(document.getElementById(styles.calendar), {
      ...getProvidingDefaults(),
      // customButtons: {
      //   save: {
      //     text: 'Save',
      //     click: this.handleSave,
      //   },
      // },
      // eventClick: info => this.handleClick(info.el, info.event),
      // eventDrop: info => {
      //   this.setState({
      //     events: this.state.events.filter(e => e.id != info.event.id).concat(info.event),
      //     popover: null,
      //   });
      // },
      // eventOverlap: (still, moving) => {
      //   return still.extendedProps.type === 'external' &&
      //          moving.extendedProps.type === 'available';
      // },
      // eventSources:[
      //   { id: 'events', events: (_, cb) => cb(this.state.events) },
      //   { id: 'external', events: (_, cb) => cb(this.generateExternalEventSource()) },
      //   { id: 'past', events: (_, cb) => cb(CalendarBase.generateBackgroundEventSource()) },
      //   { id: 'moratorium', events: (_, cb) => cb(CalendarBase.generateMoratoriumEventSource({ minutes: this.props.user.settings.callSchedulingMoratorium })) },
      // ],
      // eventResize: info => {
      //   this.setState({
      //     events: this.state.events.filter(e => e.id != info.event.id).concat(info.event),
      //     popover: null,
      //   });
      // },
      // select: this.handleSelect,
      // selectAllow: info => {
      //   if (isAfter(Date.now(), new Date(info.start))) {
      //     return false;
      //   }

      //   return true;
      // },
      // windowResize: v => this.calendar.changeView(getResponsiveCompatibleView()),
    });

    return cal;
  }, []);

  const renderCalendar = useCallback(() => {
    calendar.render();
    const nowLine = document.getElementsByClassName('fc-now-indicator fc-now-indicator-line');
    if (nowLine && nowLine.length) {
      setTimeout(() => {
        nowLine[0]?.scrollIntoView({
          block: 'center',
        });
      }, 0);
    }
  }, [calendar]);

  return {
    ...calendar,
    id: styles.calendar,
    gotoDate: (data: Date) => calendar?.gotoDate?.(data),
    onClickContainer: () => {},
    renderCalendar,
  };
};

type SelectParams = {
  start: Date;
  end: Date;
  startStr: string;
  endStr: string;
  allDay: boolean;
  resource?: unknown;
  jsEvent: MouseEvent;
  view: View;
};

type PopoverState = {
  el: string;
  event: FullCalEvent;
};

type EventClickParams = {
  el: HTMLElement;
  event: EventApi;
  jsEvent: MouseEvent;
  view: View;
};