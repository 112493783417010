import { memo, useState } from 'react';
import styled from '@emotion/styled';
import { useFloating, useHover, useInteractions } from '@floating-ui/react';
import { BasicMarkdown } from '@/components/presentation/Markdown/Markdown';
import * as Presentation from '@/components/presentation/Message.QueryHint/presentation';

const AIRoot = styled.div(({ theme }) => {
  const { Text } = Presentation.Colors(theme);
  return {
    cursor: 'default',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    height: 22,
    width: 22,
    marginLeft: 5,
    padding: 6,
    border: `2px solid ${Text.enabled.hover.backgroundColor}`,
  };
});

const AIText = styled.div(({ theme }) => {
  const { TextIcon } = Presentation.Colors(theme);
  return {
    color: TextIcon.enabled.color,
    fontFamily: theme.fonts.bold,
    fontSize: 11,
    marginRight: 1,
    marginBottom: 1,
  };
});

type AISymbolProps = {
  helpText: string;
};

export const AITooltip = memo(({ helpText }: AISymbolProps) => {

  const [isOpen, setIsOpen] = useState(false);

  const { context, refs, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
  ]);

  return (
    <>
      <AIRoot ref={refs.setReference} {...getReferenceProps()}>
        <AIText>AI</AIText>
      </AIRoot>
      {isOpen && (
        <PopperMenu
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <Copy>
            <BasicMarkdown>{helpText}</BasicMarkdown>
          </Copy>
        </PopperMenu>
      )}
    </>
  );
});

const Copy = styled.div(({ theme }) => {
  return {
    fontFamily: theme.fonts.regular,
    fontSize: 14,
  };
});

const PopperMenu = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  padding: 15,
  borderRadius: 4,
  boxShadow: `0 2px 6px 2px rgba(60, 64, 67, .15)`,
  zIndex: 4,
  width: 400,

  textAlign: 'left',
}));