import { useContext } from 'react';
import { TabViewSkeleton } from '@/components/TabView';
import { ProjectStateLoadingContext } from './Context';
import { Header } from './Project.Header';
import { ProjectTabViewContainer } from './Project.Tabs.Container';
import styles from './style/ProjectTab.module.css';

type Props = unknown;

export const ProjectTabs = (props: Props) => {
  const loading = useContext(ProjectStateLoadingContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Header />
        {loading
          ? <TabViewSkeleton />
          : <ProjectTabViewContainer />
        }
      </div>
    </div>
  );
};

ProjectTabs.displayName = 'Project.Tabs';