export enum BrandColorCategory {
  Options = 1,
  Matrix  = 2,
  Ranking = 3,
}

export enum ColorPaletteType {
  Preset = 1,
  Brand  = 2,
}

export enum MediaOrientation {
  Square = 1,
  Horizontal = 2,
}
