type Node = {
  children: Node[];
  type: string;
  value: string;
};


export const remarkCitations = () => {
  const MinCitationsToGroup = 5;

  return tree => {
    const visit = (node: Node) => {
      if (node.type === 'text' && /\^\d+[a-z]*\^/.test(node.value)) {
        const citationPattern = /\^(\d+[a-z]*)\^/g;
        const matches = Array.from(node.value.matchAll(citationPattern));
        const children = [];
        let lastIndex = 0;

        for (let i = 0; i < matches.length; i++) {
          const match = matches[i];

          const start = match.index;
          const end = start + match[0].length;

          if (start > lastIndex) {
            children.push({
              type: 'text',
              value: node.value.slice(lastIndex, start),
            });
          }

          // Collect consecutive citations into a single `citations` node
          const citations: string[] = [];
          let nextIndex = i;
          while (
            nextIndex < matches.length &&
            matches[nextIndex].index === matches[i].index + (nextIndex - i) * match[0].length
          ) {
            citations.push(matches[nextIndex][1]);
            nextIndex++;
          }

          if (citations.length >= MinCitationsToGroup) {
            children.push({
              type: 'citations',
              data: {
                hName: 'citations',
                hProperties: {
                  values: citations,
                },
              },
            });
          } else {
            for (let j = i; j < nextIndex; j++) {
              children.push({
                type: 'citation',
                data: {
                  hName: 'citation',
                  hProperties: {
                    value: matches[j][1],
                  },
                },
              });
            }
          }

          lastIndex = end + (nextIndex - i - 1) * match[0].length;
          i = nextIndex - 1;
        }

        if (lastIndex < node.value.length) {
          children.push({
            type: 'text',
            value: node.value.slice(lastIndex),
          });
        }

        return children;
      }

      return [node];
    };

    const transformer = (node: Node) => {
      if (Array.isArray(node.children)) {
        node.children = node.children.flatMap((child) =>
          visit(child).flatMap(transformer),
        );
      }
      return node;
    };

    const transformed = transformer(tree);
    return transformed;
  };
};