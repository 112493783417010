export enum ChatDataSource {
  IConnect = 'i-connect',
  MEAI = 'me-ai',
  VeevaLink = 'veeva-link',
}

export const ChatDataSourcesMap = new Map<ChatDataSource, string>(
  [
    [ChatDataSource.IConnect, 'iConnect'],
    [ChatDataSource.MEAI, 'ME.AI'],
    [ChatDataSource.VeevaLink, 'Veeva Link'],
  ],
);

export enum ChatSource {
  MslInteractions = 'msl-interactions',
  Presentations = 'presentations',
  Profiles = 'profiles',
  Text = 'text',
}

export const ChatSourcesMap = new Map<ChatSource, string>(
  [
    [ChatSource.Profiles, 'Profiles'],
    [ChatSource.MslInteractions, 'CRM Interactions'],
    [ChatSource.Presentations, 'Presentations'],
    [ChatSource.Text, 'Text'],
  ],
);

export enum ChatCustomFilter {
  InsightType = 'insight-type',
  InteractionDate = 'interaction-date',
  InteractionType = 'interaction-type',
  InteractionSubType = 'interaction-sub-type',
  InteractionDetail = 'interaction-detail',
  InterestArea = 'interest-area',
  TherapeuticArea = 'therapeutic-area',
  TherapeuticSubArea = 'therapeutic-sub-area',
  Product = 'product',
  Sentiment = 'sentiment',
  Source = 'source',
  PracticeSetting = 'practice-setting',

  CallCenterSource = 'call-center-source',
  CallCenterSubSource = 'call-center-sub-source',
}

export const chatCustomFilterNamesMap: Record<ChatCustomFilter, string> = {
  [ChatCustomFilter.CallCenterSource]: 'Call Center Source',
  [ChatCustomFilter.CallCenterSubSource]: 'Call Center Sub Source',
  [ChatCustomFilter.InteractionDate]: 'Interaction Date',
  [ChatCustomFilter.InteractionType]: 'Interaction Type',
  [ChatCustomFilter.InteractionSubType]: 'Interaction Sub Type',
  [ChatCustomFilter.InteractionDetail]: 'Interaction Detail',
  [ChatCustomFilter.TherapeuticArea]: 'Therapeutic Area',
  [ChatCustomFilter.TherapeuticSubArea]: 'Therapeutic Sub Area',
  [ChatCustomFilter.Product]: 'Product',
  [ChatCustomFilter.Sentiment]: 'Sentiment',
  [ChatCustomFilter.Source]: 'Source',
  [ChatCustomFilter.InsightType]: 'Insight Type',
  [ChatCustomFilter.InterestArea]: 'Interest Area',
  [ChatCustomFilter.PracticeSetting]: 'Practice Setting',
};

export enum ChatFilterOperator {
  Eq = 'eq',
  Neq = 'neq',
  // Gt = 'gt',
  // Gte = 'gte',
  // Lt = 'lt',
  // Lte = 'lte',

  MoreRecentThan = 'more-recent-than',
  PriorTo = 'prior-to',
}

export const chatFilterOperatorsMap: Record<ChatFilterOperator, string> = {
  [ChatFilterOperator.Eq]: 'Equals',
  [ChatFilterOperator.Neq]: 'Not Equals',
  // [ChatFilterOperator.Gt]: 'Greater Than',
  // [ChatFilterOperator.Gte]: 'Greater Than or Equals',
  // [ChatFilterOperator.Lt]: 'Less Than',
  // [ChatFilterOperator.Lte]: 'Less Than or Equals',

  [ChatFilterOperator.MoreRecentThan]: 'More Recent Than',
  [ChatFilterOperator.PriorTo]: 'Prior To',
};

export enum ChatDateFilter {
  Last7Days = 'last-7-days',
  Last30Days = 'last-30-days',
  Last90Days = 'last-90-days',
  Last3Months = 'last-3-months',
  Last6Months = 'last-6-months',
  LastYear = 'last-year',
  Last2Years = 'last-2-years',
  Last5Years = 'last-5-years',
}

export const chatDateFiltersMap: Record<ChatDateFilter, string> = {
  [ChatDateFilter.Last7Days]: 'Last 7 Days',
  [ChatDateFilter.Last30Days]: 'Last 30 Days',
  [ChatDateFilter.Last90Days]: 'Last 90 Days',
  [ChatDateFilter.Last3Months]: 'Last 3 Months',
  [ChatDateFilter.Last6Months]: 'Last 6 Months',
  [ChatDateFilter.LastYear]: 'Last Year',
  [ChatDateFilter.Last2Years]: 'Last 2 Years',
  [ChatDateFilter.Last5Years]: 'Last 5 Years',
};