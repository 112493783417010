/*import type { WorkspaceObjects } from '@/api/interfaces/workspaces';

type ChildrenGetData = WorkspaceObjects.GetObjectChildren.Request;

export const Children = {
  Base: `get:workspace/object/children`,
  Get: (data: ChildrenGetData) => [Children.Base, data] as const,
};

type SharedObjectsGetData = WorkspaceObjects.GetSharedObjects.Request;

export const SharedObjects = {
  Base: `get:workspace/shared`,
  Get: (data: SharedObjectsGetData) => [SharedObjects.Base, data] as const,
};

export const FilePreview = {
  Base: (data: IWorkspaceFileId) => `get:workspace/file/preview/${data.fileId}`,
  Get: (data: IWorkspaceFileId) => [FilePreview.Base(data)] as const,
};

*/

export namespace Workspaces {

  export const FileSummary = {
    Get: (data: IWorkspaceFileId) => [`get:workspace/file/summary/${data.fileId}`] as const,
    Post: (data: IWorkspaceFileId) => [`post:workspace/file/summary/${data.fileId}`] as const,
  };

}
/*

export const Folder = {
  Base: (data: IWorkspaceFolderId) => `get:workspace/folder/${data.folderId}`,
  Get: (data: IWorkspaceFolderId) => [Folder.Base(data)] as const,
};

export const Brand = {
  Base: (data: IWorkspaceBrandId) => `get:workspace/brand/${data.brandId}`,
  Get: (data: IWorkspaceBrandId) => [Brand.Base(data)] as const,
};

*/

export const Groups = {
  Branding: {
    Get: `get:groups/branding`,
    Logo: {
      Delete: `delete:groups/branding/logo`,
      Post: `post:groups/branding/logo`,
    },
    Palettes: {
      Delete: `delete:groups/branding/palettes`,
      Post: `post:groups/branding/palettes`,
      Put: `put:groups/branding/palettes`,
    },
    Theming: {
      Put: `put:groups/branding/theming`,
    },
    Survey: {
      Pages: {
        Completion: {
          Image: {
            Delete: `delete:groups/branding/surveys/pages/completion/image`,
            Post: `post:groups/branding/surveys/pages/completion/image`,
          },
          Text: {
            Put: `put:groups/branding/surveys/pages/completion/text`,
          },
        },
      },
      Palettes: {
        Put: `put:groups/branding/surveys/palettes`,
      },
      Theming: {
        Get: `get:groups/surveys/theming`,
      },
    },
  },
};