import { ChatQueryStatus } from '@/enums';
import { getQuerySiblings } from '@/utils/queries';
import type { ChatState, QueriesState } from './interfaces';

export function queriesReducer(state: QueriesState.State, action: ChatState.Action): QueriesState.State {

  switch (action.type) {

    case 'query-submitted': {
      state[action.payload.identifier] = {
        createdOn: new Date(),
        id: null,
        identifier: action.payload.identifier,
        value: action.payload.value,
        rating: null,
        response: null,
        state: null,
        status: ChatQueryStatus.Loading,
        citations: [],
        mapping: {
          parentIdentifier: action.payload.parentQueryIdentifier,
          childrenIdentifiers: [],
        },
        suggestedFollowups: [],
        hideUserMessage: action.payload.hideUserMessage,
      };

      // remove siblings that are errors, basically only persist 1 errored message for display purposes.
      // const siblings = getQuerySiblings({
      //   queryIdentifier: action.payload.identifier,
      //   queries: state,
      // });

      // const erroredIdentifiers = siblings.filter(q => q.status === 'error').map(q => q.identifier);

      // for (const errorIdentifier of erroredIdentifiers) {
      //   delete state[errorIdentifier];
      // }
      const erroredIdentifiers = [];

      // update parent query, add this submitted variant as a child to its children mapping
      if (action.payload.parentQueryIdentifier) {
        const parent = state[action.payload.parentQueryIdentifier];
        state[action.payload.parentQueryIdentifier] = {
          ...parent,
          mapping: {
            ...parent.mapping,
            childrenIdentifiers: [
              ...parent.mapping.childrenIdentifiers,
              action.payload.identifier,
            ].filter(identifier => !erroredIdentifiers.includes(identifier)),
          },
        };
      }

      return Object.assign({}, state);
    }

    case 'query-variant-submitted': {
      const source = state[action.payload.sourceIdentifier];
      state[action.payload.identifier] = {
        createdOn: new Date(),
        id: null,
        identifier: action.payload.identifier,
        value: source.value,
        rating: null,
        response: null,
        state: {},
        status: ChatQueryStatus.Loading,
        citations: [],
        mapping: {
          parentIdentifier: source.mapping.parentIdentifier,
          childrenIdentifiers: [],
        },
        suggestedFollowups: [],
        hideUserMessage: action.payload.hideUserMessage,
      };

      // remove siblings that are errors, basically only persist 1 errored message for display purposes.
      // const siblings = getQuerySiblings({
      //   queryIdentifier: source.identifier,
      //   queries: state,
      // });

      // const erroredIdentifiers = siblings.filter(q => q.status === 'error').map(q => q.identifier);

      // for (const errorIdentifier of erroredIdentifiers) {
      //   delete state[errorIdentifier];
      // }
      const erroredIdentifiers = [];

      // update parent query, add this submitted variant as a child to its children mapping
      if (source.mapping.parentIdentifier) {
        state[source.mapping.parentIdentifier] = {
          ...state[source.mapping.parentIdentifier],
          mapping: {
            ...state[source.mapping.parentIdentifier].mapping,
            childrenIdentifiers: [
              ...state[source.mapping.parentIdentifier].mapping.childrenIdentifiers,
              action.payload.identifier,
            ].filter(identifier => !erroredIdentifiers.includes(identifier)),
          },
        };
      }

      return Object.assign({}, state);
    }

    case 'query-response-available':
    case 'query-variant-response-available': {
      state[action.payload.query.identifier] = {
        ...state[action.payload.query.identifier],
        citations: action.payload.query.citations,
        id: action.payload.query.id,
        response: action.payload.query.response,
        mapping: action.payload.query.mapping,
        status: ChatQueryStatus.Complete,
        suggestedFollowups: action.payload.query.suggestedFollowups,
        langfuseTraceUrl: action.payload.query.langfuseTraceUrl,
        state: action.payload.query.state,
      };

      return Object.assign({}, state);
    }

    case 'query-errored':
    case 'query-variant-errored': {
      state[action.payload.queryIdentifier] = {
        ...state[action.payload.queryIdentifier],
        status: ChatQueryStatus.Error,
        sentryTraceId: action.payload.traceId,
      };
      return Object.assign({}, state);
    }

    case 'query-rated': {
      state[action.payload.queryIdentifier] = Object.assign({}, state[action.payload.queryIdentifier], {
        rating: action.payload.rating,
      });
      return Object.assign({}, state);
    }

    case 'query-taking-long': {
      state[action.payload.queryIdentifier] = {
        ...state[action.payload.queryIdentifier],
        status: ChatQueryStatus.LoadingExtended,
      };
      return Object.assign({}, state);
    }

    case 'query-followups-available': {
      state[action.payload.queryIdentifier] = {
        ...state[action.payload.queryIdentifier],
        suggestedFollowups: action.payload.items,
        status: ChatQueryStatus.FollowupsAvailable,
      };
      return Object.assign({}, state);
    }

    default:
      return state;
  }
}