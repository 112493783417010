import type { ComponentType } from 'react';
import { useCallback, useContext, useState } from 'react';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { FileUploadReviewStateContext, FileUploadReviewDispatchContext } from '@/containers/WorkspaceFileUpload/Context';
import type { FileUploadReview as FileUploadReviewState } from '@/containers/WorkspaceFileUpload/interfaces';
import { useStepper } from '@/utils/hooks';
import { FileUploadReviewStepperActionsContext } from './context';
import { ExistingFilesReview } from './Review.ExistingFiles';
import { FileUploadReview } from './Review.FilesReview';
import { TagsReview } from './Review.Tags';
import { TranscriptionReview } from './Review.Transcription';
import styles from './style/Review.Modal.module.css';

type Props =
  Pick<ModalProps, 'open' | 'onClose'>;

export const FileUploadReviewModal = ({ onClose, open }: Props) => {

  const dispatch = useContext(FileUploadReviewDispatchContext);
  const state = useContext(FileUploadReviewStateContext);

  const [screens] = useState<ComponentType[]>(computeSteps(state));

  const [Screen, { next }, index] = useStepper(screens);

  const handleNextStep = useCallback(() => {
    if (index === screens.length - 1) {
      dispatch({ type: 'upload-initiated' });
    } else {
      next();
    }
  }, [dispatch, index, next, screens.length]);

  const actionsCtx = {
    next: handleNextStep,
  };

  return (
    <Modal
      onClose={onClose}
      open={open}>
      <FileUploadReviewStepperActionsContext.Provider value={actionsCtx}>
        <div className={styles.root}>
          <Screen />
        </div>
      </FileUploadReviewStepperActionsContext.Provider>
    </Modal>
  );
};

function computeSteps(state: FileUploadReviewState.State): ComponentType[] {
  const items: ComponentType[] = [];
  if (state?.existing && Object.keys(state.existing).length) {
    items.push(ExistingFilesReview);
  }
  if (state?.transcribeable?.length) {
    items.push(TranscriptionReview);
    items.push(FileUploadReview);
  }

  items.push(TagsReview);
  return items;
}