import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import type { FileUploadFile } from '@/types';
import { useThrottleCallback } from '@/utils/hooks';
import {
  UploadFilesBaseContext,
} from './Context';
import {
  useFileUploadEffects,
  useUploadFileToS3,
} from './hooks';
import type { UploadFileParams, UploadFilesParams } from './interfaces';

type Props = {
  children: React.ReactNode;
  onInvalidFile?: (file: FileUploadFile) => void;
};

const MaxConcurrentFileUploads = 3;

export const UploadFilesContainer = ({
  onInvalidFile,
  ...props
}: Props) => {

  const effects = useFileUploadEffects();

  const uploadFileToS3 = useUploadFileToS3();
  const uploadFileToS3Throttled = useThrottleCallback(uploadFileToS3, MaxConcurrentFileUploads);

  const uploadFile = useCallback(async (data: UploadFileParams) => {
    const file = {
      identifier: data.identifier,
      lastModified: data.file.lastModified,
      name: data.file.name,
      size: data.file.size,
      type: data.file.type,
    };

    try {
      const { fileUpload } = await effects.queue({
        file,
        options: data.options,
        parentObjectId: data.parentObjectId,
        uploadIdentifier: data.uploadIdentifier,
        workspaceId: data.workspaceId,
      });

      return uploadFileToS3Throttled(data.file, fileUpload);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      onInvalidFile?.(file);
    }
  }, [
    effects,
    onInvalidFile,
    uploadFileToS3Throttled,
  ]);

  const uploadFiles = useCallback((data: UploadFilesParams) => {
    for (const identifier of Object.keys(data.files)) {
      const file = data.files[identifier];
      uploadFile({
        file,
        identifier,
        options: {
          convertToTranscript: data.isTranscriptImport,
          replaceExisting: data.replaceExisting,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tags: data.tags,
          transcribe: false, //data.transcribeable.includes(identifier),
        },
        parentObjectId: data.parentObjectId,
        workspaceId: data.workspaceId,
        uploadIdentifier: data.uploadIdentifier,
      });
    }
  }, [
    uploadFile,
  ]);

  return (
    <UploadFilesBaseContext.Provider value={uploadFiles}>
      {props.children}
    </UploadFilesBaseContext.Provider>
  );
};