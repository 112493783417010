import { CalendarPickerBase } from '@/components/CalendarPicker/CalendarPickerBase';
import { cx } from '@/utils';
import type { CalendarPickerBaseProps } from './interfaces';
import styles from './style.module.css';

type Props = {
  className?: string;
  dates?:     Date[];
  marked?:    Date[];
} & CalendarPickerBaseProps;

const CalendarPicker = ({
  calendarClassName = styles.root,
  inline = true,
  marked = [],
  ...props
}: Props) => {
  const {
    className,
    dates,
    highlightDates,
    includeDates,
    ...rest
  } = props;

  const enableHighlightedDatesOnly = dates?.length;

  const highlighted = highlightDates
    ? highlightDates
    : enableHighlightedDatesOnly
      ? [{ [styles.highlight]: dates }]
      : [{ [styles.highlight]: marked }];

  const included = includeDates
    ? includeDates
    : enableHighlightedDatesOnly
      ? dates
      : null;

  return (
    <CalendarPickerBase
      {...rest}
      inline={inline}
      calendarClassName={cx(calendarClassName, className)}
      highlightDates={highlighted}
      includeDates={included} />
  );
};

export { CalendarPicker };
export default CalendarPicker;