import type { Root, Table, TableRow, TableCell } from 'mdast';
import { toString } from 'mdast-util-to-string';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import { unified } from 'unified';
import { removeMarkdownCitations } from '@/utils/markdown';

let activeUtterance: SpeechSynthesisUtterance | null = null;
let activeContent: string | null = null;
let isSpeaking = false;

const preprocessContent = (text: string): string => {
  if (!text || typeof text !== 'string' || !text.trim()) return '';

  const transformedText = transformMarkdownTables(text);

  const punctuatedText = transformedText
    .split('\n')
    .map(line => {
      const trimmed = line.trim();
      if (!trimmed) return ''; // skip empty lines
      // Skip lines that consist solely of a number.
      if (/^\d+$/.test(trimmed)) return '';
      // Check if the line ends with ., !, or ?
      if (/[.?!]$/.test(trimmed)) {
        return trimmed;
      } else {
        // Append a period to force a pause
        return trimmed + '.';
      }
    })
    .filter(line => line !== '') // Remove any empty strings resulting from skipped lines.
    .join('\n');

  return removeMarkdownCitations(
    punctuatedText
      .replace(/\*/g, '')
      .replace(/\bAI\b/g, '')
      .replace(/\bPharmD\b/g, 'Pharm Dee'),
  ).trim();
};

function transformMarkdownTables(markdown: string): string {
  try {
    const tree = unified()
      .use(remarkParse)
      .use(remarkGfm)
      .parse(markdown) as Root;

    let result = '';

    tree.children.forEach((node) => {
      if (node.type === 'table') {
        const tableText = tableToSpeechText(node);
        console.log('Extracted Table Text:', tableText);
        result += tableText + '\n\n';
      } else {
        result += toString(node) + '\n\n';
      }
    });

    console.log('Final Processed Markdown:', result);
    return result || markdown;
  } catch (error) {
    console.error('Error processing markdown:', error);
    return markdown;
  }
}

function tableToSpeechText(table: Table): string {
  // Get header values as strings.
  const headers = table.children[0].children.map(
    (cell: TableCell) => toString(cell).trim(),
  );
  // Determine which header indices to skip (if header is "Source")
  const skipIndices = headers
    .map((header, index) => (header.toLowerCase() === 'source' ? index : -1))
    .filter(index => index !== -1);

  // Process each row (skip the header row).
  return table.children.slice(1).map((row, rowIndex) => {
    // Build an array of cell strings, but skip columns with indices in skipIndices.
    const cells = row.children
      .map((cell, colIndex) => {
        if (skipIndices.includes(colIndex)) {
          return null;
        }
        return `${headers[colIndex]}: ${toString(cell).trim()}`;
      })
      .filter(Boolean); // Remove any null values.
    return `Row ${rowIndex + 1}: ${cells.join(', ')}`;
  }).join('\n');
}

export function startSpeech(
  content: string,
  callbacks: { onStart?: () => void; onEnd?: () => void; onError?: (error: any) => void } = {},
) {
  const synth = window.speechSynthesis;

  if (activeUtterance) {
    synth.cancel(); // Cancel any ongoing speech
  }

  const utterance = new SpeechSynthesisUtterance(content);
  utterance.lang = 'en-US';
  utterance.rate = 1;
  utterance.pitch = 1;

  utterance.onstart = () => {
    isSpeaking = true;
    activeUtterance = utterance;
    activeContent = content;
    callbacks.onStart?.();
  };

  utterance.onend = () => {
    isSpeaking = false;
    activeUtterance = null;
    activeContent = null;
    callbacks.onEnd?.();
  };

  utterance.onerror = (error) => {
    console.error('Speech synthesis error:', error);
    isSpeaking = false;
    activeUtterance = null;
    activeContent = null;
    callbacks.onError?.(error);
  };

  synth.speak(utterance);
}

export function stopSpeech() {
  if (activeUtterance) {
    window.speechSynthesis.cancel();
    isSpeaking = false;
    activeUtterance = null;
    activeContent = null;
  }
}

export function isSpeechActive(content?: string) {
  if (!content) {
    return isSpeaking;
  }
  return activeContent === content;
}

export { preprocessContent };
