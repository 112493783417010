import { useCallback, useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Edit2 as Edit, Trash } from 'react-feather';
import { cx } from '@/utils';
import { ThemeGroupContext, ThemeGroupSelectContext } from './Context';
import type { Theme as T } from './interfaces';
import styles from './style/Select.module.css';

type Props = {
  editable?: boolean;
  item:      T;
  readonly?: boolean;
};

export const Option = (props: Props) => {
  const { deletePalette, editPalette, setTheme } = useContext(ThemeGroupContext);
  const select = useContext(ThemeGroupSelectContext);

  const handleClick = useCallback(() => {
    if (!props.readonly) {
      setTheme(props.item.id);
      select.close();
    }
  }, [
    props.item.id,
    props.readonly,
    select,
    setTheme,
  ]);

  const handleDelete = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();

    deletePalette(props.item.id);

  }, [
    deletePalette,
    props.item,
  ]);

  const handleEdit = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();

    editPalette(props.item.id);

    select.close();

  }, [
    editPalette,
    props.item,
    select,
  ]);

  const optionclasses = cx(styles.item, {
    [styles.editable]: props.editable,
    [styles.option]: !props.readonly,
    [styles.preset]: !props.editable && !props.readonly,
  });

  return (
    <MenuItem
      classes={{ root: cx(styles.input, { [styles.readonly]: props.readonly }) }}
      disableRipple>
      <div
        className={optionclasses}
        onClick={handleClick}>
        <div className={styles.info}>
          <div className={styles.name}>
            {props.item.name}
          </div>

          <div className={styles.details}>
            <div className={styles.palette}>
              {props.item.colors.slice(0, 20).map((x, i) =>
                <div
                  className={styles.color}
                  key={`${i}-${x}`}
                  style={{ backgroundColor: x }} />)}
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          {(props.editable && !props.readonly) &&
            <>
              <button
                className={styles.delete}
                onClick={handleDelete}>
                <Trash className={styles.icon} />
              </button>
              <button
                className={styles.edit}
                onClick={handleEdit}>
                <Edit className={styles.icon} />
              </button>
            </>}
        </div>
      </div>
    </MenuItem>
  );
};

Option.displayName = 'ColorPalette.Select.Option';