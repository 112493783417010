import { useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { StopCircle } from 'react-feather';
import { InitiateQuerySpeechToText, TextToSpeechStateContext, StopTextToSpeechContext } from '@/containers/TextToSpeechAudioPlayer';
import { useHasBetaPermission, useHasChatReadAloudEnabled } from '@/store/selectors';
import { BaseMessageButton } from './Message.Button';

type Props = {
  kolSearchId?: number | null;
  queryId?: number | null;
  getContent?: () => string;
};

export const ReadAloudButton = ({ kolSearchId, queryId, getContent }: Props) => {
  const hasBetaPermission = useHasBetaPermission();
  const hasChatReadAloudEnabled = useHasChatReadAloudEnabled();

  const initiateReadAloud = useContext(InitiateQuerySpeechToText);
  const stopTextToSpeech = useContext(StopTextToSpeechContext);
  const audioState = useContext(TextToSpeechStateContext);

  const isPlaying =
    audioState &&
    audioState.isPlaying &&
    (audioState.queryId === queryId || audioState.kolSearchId === kolSearchId);

  const handleClick = useCallback(async () => {
    if (isPlaying) {
      stopTextToSpeech?.();
      return;
    }
    return initiateReadAloud({
      kolSearchId,
      queryId,
      text: getContent ? getContent() : null,
      isPlaying: true,
    });
  }, [isPlaying, stopTextToSpeech, initiateReadAloud, kolSearchId, queryId, getContent]);

  if (!hasBetaPermission || !hasChatReadAloudEnabled) return null;

  return (
    <StyledButton onClick={handleClick}>
      {isPlaying ? <StopCircle size={18} /> : <PlayArrowOutlinedIcon fontSize="small" />}
      {isPlaying ? 'Stop' : 'Read Aloud'}
    </StyledButton>
  );
};

const StyledButton = styled(BaseMessageButton)({
  display: 'flex',
  justifyContent: 'left',
  gap: '4px',
});