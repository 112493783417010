import { useContext } from 'react';
import MUICheckbox from '@mui/material/Checkbox';
import { AppThemingContext } from '@/containers/AppTheming/Context';

type Props = {
  checked: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const Checkbox = (props: Props) => {
  const { palette, theme } = useContext(AppThemingContext);

  const checkedColor = theme?.enabled
    ? palette.primary.main
    : `var(--pri-02)`;

  return (
    <MUICheckbox
      disableRipple={true}
      color="default"
      sx={{
        color: 'var(--gray-d)',
        padding: 0,
        marginRight: '6px',
        flexShrink: 0,
        [`&.Mui-checked`]: {
          color: checkedColor,
        },
      }}
      checked={props.checked ?? false}
      onChange={props.onChange} />
  );
};

Checkbox.displayName = 'Checkbox';