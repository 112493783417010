import { memo, useMemo, Fragment, useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import type { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import { ChatMarkdown } from '@/components/presentation/Markdown/Markdown';
import { Table } from '@/components/presentation/Markdown/MarkdownElements';
import { ChatResponseType } from '@/enums';
import { ReportFormat } from '@/enums/reports';
import type { Chat } from '@/types';
import { ActiveChatSessionContext } from './context';
import { useDownloadQueryTable } from './hooks/useDownloadQueryTable';
import { DownloadButton } from './Message.Download';
import type { ResponseItem } from './Message.Response.utils';
import { parseResponse } from './Message.Response.utils';

type MessageResponse<T extends ChatResponseType = ChatResponseType> = {
  queryIdentifier: string;
  item: Chat.QueryResponse<T>;
};

export const MessageResponse = ({ item, queryIdentifier }: MessageResponse) => {

  switch (item.type) {
    case ChatResponseType.Text:
      return <MessageResponseRawText value={item.value} />;
    case ChatResponseType.Markdown:
      return (
        <Markdown
          queryIdentifier={queryIdentifier}
          value={item.value} />
      );
    case ChatResponseType.HcpSummary:
    case ChatResponseType.HcpSelection:
      return null; // This is implemented at the top level

    default:
      throw new UnreachableCaseError(item);
  }
};

type MarkdownProps = {
  queryIdentifier: string;
  value: string;
};

const Markdown = memo(({ queryIdentifier, value }: MarkdownProps) => {

  const session = useContext(ActiveChatSessionContext);

  const { download } = useDownloadQueryTable({
    chatInstanceId: session.id,
    queryIdentifier,
  });

  const handleDownload = useCallback(() => {
    download({
      title: `Generating Export`,
      name: `Table Data`,
      extension: ReportFormat.Excel,
    });
  }, [download]);

  const customMarkdownComponents = useMemo((): ReactMarkdownOptions['components'] => ({
    table: props => (
      <>
        <StyledTable>
          {props.children}
        </StyledTable>
        <DownloadButton
          label="Download List"
          onClick={handleDownload} />
      </>
    ),
  }), [handleDownload]);

  return (
    <ChatMarkdown components={customMarkdownComponents} value={value} />
  );
});

type MessageResponseRawTextProps = {
  value: string;
};

export const MessageResponseRawText = memo(({ value }: MessageResponseRawTextProps) => {

  const items = useMemo(() => {
    return parseResponse(value);
  }, [value]);

  const renderItem = useCallback((item: ResponseItem, i: number) => {
    switch (item.type) {
      case 'bullets': {
        return (
          <StyledUl key={i}>
            {item.value.map((value, j) => (
              <StyledLi key={j}>{value}</StyledLi>
            ))}
          </StyledUl>
        );
      }
      case 'numbered': {
        return (
          <StyledOl key={i}>
            {item.value.map((value, j) => (
              <StyledLi key={j}>{value}</StyledLi>
            ))}
          </StyledOl>
        );
      }
      case 'text': {
        if (item.value === '') return <br key={i} />;
        return (
          <div key={i}>{item.value}</div>
        );
      }
    }
  }, []);

  return (
    <Fragment>
      {items.map(renderItem)}
    </Fragment>
  );
});

const StyledLi = styled('li')({
  marginBottom: 4,

  '&:last-child': {
    marginBottom: 0,
  },
});

const StyledOl = styled('ol')({
  paddingLeft: 20,

  [`& > li`]: {
    paddingLeft: 5,
    marginBottom: 8,
    '&::marker': {
      fontFamily: 'var(--font-semibold)',
    },
  },
});

const StyledUl = styled('ul')({
  paddingInlineStart: 20,
});

const StyledTable = styled(Table)({
  marginTop: 15,
});
