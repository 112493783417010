import { useCallback, useContext, useEffect } from 'react';
import { FileUploadsProgress } from '@/components/FileUploadsProgress';
import { FileUploadStatus } from '@/enums';
import { useBeforeUnload } from '@/utils/hooks';
import {
  AbortFileUploadContext,
  ToggleUploadDisplayContext,
  FileUploadsDispatchContext,
  FileUploadsStateContext,
} from './Context';
import {
  useAbortUpload,
  useShowUploadDisplay,
  useUploadInProgress,
} from './hooks';

type Props = {
  children: React.ReactNode;
};

export const FileUploadsProgressContainer = (props: Props) => {
  const dispatch = useContext(FileUploadsDispatchContext);
  const state = useContext(FileUploadsStateContext);
  const [showDisplay, toggleDisplay] = useShowUploadDisplay();

  const inProgress = useUploadInProgress();

  useBeforeUnload({ block: inProgress });

  const abortUpload = useAbortUpload();

  const abortUploads = useCallback(() => {
    return Promise.all(
      state.valid
        .filter(f => [FileUploadStatus.InProgress, FileUploadStatus.Enqueued].includes(f.status))
        .map(m => abortUpload(m.identifier)),
    );
  }, [
    abortUpload,
    state.valid,
  ]);

  useEffect(() => {
    if (!showDisplay) {
      dispatch({
        type: 'remove-completed-uploads',
      });
    }
  }, [dispatch, showDisplay]);

  return (
    <AbortFileUploadContext.Provider value={abortUpload}>
      <ToggleUploadDisplayContext.Provider value={toggleDisplay}>
        {props.children}
        {showDisplay && <FileUploadsProgress />}
      </ToggleUploadDisplayContext.Provider>
    </AbortFileUploadContext.Provider>
  );
};

export default FileUploadsProgressContainer;