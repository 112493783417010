import { useMemo } from 'react';
import styled from '@emotion/styled';
import { ChatCitationType, type InteractionType } from '@/enums';
import type { InteractionTypeEnum } from '@/enums/interactions';
import type { Chat } from '@/types/chat';
import { MessageCitation } from './Citation';
import { InteractionCitations } from './Citations.Interactions';

type Props = {
  className?: string;
  items: Chat.Citation[];
};

export const MessageCitations = ({ className, items }: Props) => {

  const crmNoteCitations = useMemo(() => {
    const notesMap = items.reduce((acc, item) => {
      if (item.type !== ChatCitationType.CrmNote) {
        return acc;
      }

      const cItem = item as Chat.Citation<ChatCitationType.CrmNote>;

      if (acc[cItem.metadata.type]) {
        acc[cItem.metadata.type].push(cItem);
      } else {
        acc[cItem.metadata.type] = [cItem];
      }

      return acc;
    }, {} as { [key in InteractionType]: Chat.Citation<ChatCitationType.CrmNote>[] });

    for (const interactionType of Object.keys(notesMap)) {
      const notes = notesMap[interactionType as InteractionType];

      notes.sort((a, b) => {
        return a.ordinal - b.ordinal;
      });

      notesMap[interactionType as InteractionType] = notes;
    }

    return notesMap;
  }, [items]);

  const nonCrmNoteCitations = useMemo(() => {
    return items.filter(item => item.type !== ChatCitationType.CrmNote);
  }, [items]);

  const renderedCrmCitations = useMemo(() => {
    return Object.keys(crmNoteCitations).map((interactionType, i) => (
      <InteractionCitations
        key={i}
        interactionType={interactionType as InteractionTypeEnum}
        items={crmNoteCitations[interactionType as InteractionType]} />
    ));
  }, [crmNoteCitations]);

  return (
    <StyledRoot className={className}>
      {renderedCrmCitations}
      {nonCrmNoteCitations.map((item, i) => <MessageCitation key={i} item={item} />)}
    </StyledRoot>
  );
};

const StyledRoot = styled.div(({ theme }) => ({
  '& > *:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.gray.light2}`,
  },
}));