type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  value:    string;
};

export const Input = (props: Props) => {

  return (
    <input
      onChange={props.onChange}
      value={props.value} />
  );
};

Input.displayName = 'Editor.Input';