import type { CSSProperties } from 'react';
import { useContext, useMemo } from 'react';
import { HeaderHeight, ItemHeight, MaxBodyHeight, SidePadding } from '@/components/FileUploadsProgress/consts';
import { FileUploadsStateContext } from '@/containers/WorkspaceFileUpload/Context';

export const useRootStyles = () => {
  const state = useContext(FileUploadsStateContext);

  const itemCount = useMemo(() => state.invalid.length + state.valid.length, [
    state.valid.length,
    state.invalid.length,
  ]);

  const styles: CSSProperties = useMemo(() => ({
    position: 'fixed',
    top: `calc(100vh - ${HeaderHeight}px - ${Math.min(itemCount * ItemHeight, MaxBodyHeight)}px - ${SidePadding}px)`,
    left: SidePadding,
  }), [itemCount]);

  return styles;
};