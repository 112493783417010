import * as xform from '@/api/transformers';
import { FileUploadStatus } from '@/enums';
import { getAuthenticatedClient } from './client';
import type { FileUploads as WS } from './interfaces/workspaces';

export const queueFileUpload = (data: WS.QueueFileUpload.Request) => {
  const { workspaceId, ...body } = data;
  return getAuthenticatedClient().post(`/workspaces/${workspaceId}/file-uploads`, body)
    .then(res => res.data)
    .then((res: WS.QueueFileUpload.Response) => ({
      ...res,
      fileUpload: xform.normalizeFileUpload(res.fileUpload),
    }));
};

export const startFileUpload = (data: WS.StartFileUpload.Request) => {
  const { fileUploadId, workspaceId, ...body } = data;
  return getAuthenticatedClient().post(`/workspaces/${workspaceId}/file-uploads/${fileUploadId}`, {
    ...body,
    status: FileUploadStatus.InProgress,
  })
    .then(res => res.data)
    .then((res: WS.StartFileUpload.Response) => ({
      ...res,
      fileUpload: xform.normalizeFileUpload(res.fileUpload),
    }));
};

export const abortFileUpload = (data: WS.AbortFileUpload.Request) => {
  const { fileUploadId, workspaceId, ...body } = data;
  return getAuthenticatedClient().post(`/workspaces/${workspaceId}/file-uploads/${fileUploadId}`, {
    ...body,
    status: FileUploadStatus.Aborted,
  })
    .then(res => res.data)
    .then((res: WS.AbortFileUpload.Response) => ({
      ...res,
      fileUpload: xform.normalizeFileUpload(res.fileUpload),
    }));
};

export const failFileUpload = (data: WS.FailFileUpload.Request) => {
  const { fileUploadId, workspaceId, ...body } = data;
  return getAuthenticatedClient().post(`/workspaces/${workspaceId}/file-uploads/${fileUploadId}`, {
    ...body,
    status: FileUploadStatus.Failed,
  })
    .then(res => res.data)
    .then((res: WS.AbortFileUpload.Response) => ({
      ...res,
      fileUpload: xform.normalizeFileUpload(res.fileUpload),
    }));
};

export const completeFileUpload = (data: WS.CompleteFileUpload.Request) => {
  const { fileUploadId, workspaceId, ...body } = data;
  return getAuthenticatedClient().post(`/workspaces/${workspaceId}/file-uploads/${fileUploadId}`, {
    ...body,
    status: FileUploadStatus.Completed,
  })
    .then(res => res.data)
    .then((res: WS.CompleteFileUpload.Response) => ({
      ...res,
      file: xform.normalizeFile(res.file),
      fileUpload: xform.normalizeFileUpload(res.fileUpload),
      object: xform.normalizeWorkspaceObject(res.object),
    }));
};

export const fileUploadPreCheck = ({ workspaceId, ...data }: WS.FileUploadPreCheck.Request): Promise<WS.FileUploadPreCheck.Response> => {
  return getAuthenticatedClient().post(`/workspaces/${workspaceId}/file-uploads/pre-check`, data);
};