import { useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import { ChatSettingsContext } from '@/components/Chat/context';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';
import { DropdownMenuItem } from '@/components/presentation';
import { ChatContextType } from '@/enums/chat';
import { HeaderContextContext } from './context';
import { ContextSelectorStyles } from './styles';

type RendererProps = {
  onClick?: () => unknown;
};

type ContextRendererResult = {
  label: () => React.ReactNode;
  detail: () => React.ReactNode;
  menuItems: () => React.ReactNode;
};

export const useMenuRender = (props: RendererProps): ContextRendererResult => {
  const dispatch = useContext(ChatStateDispatchContext);
  const {
    displayContextType,
    setPendingContextType,
  } = useContext(HeaderContextContext);

  const handleEverythingSelected = useCallback(() => {
    dispatch({
      type: 'instance-context-changed',
      payload: {
        context: {
          type: ChatContextType.Global,
        },
      },
    });
    setPendingContextType(null);
    props.onClick?.();
  }, [dispatch, props, setPendingContextType]);

  const updatePendingContextType = useCallback((type: ChatContextType) => () => {
    setPendingContextType(type);
    props.onClick?.();
  }, [props, setPendingContextType]);

  const chatSettings = useContext(ChatSettingsContext);

  const includeContext = useCallback((v: ChatContextType) => {
    return chatSettings.availableContextTypes.includes(v);
  }, [chatSettings.availableContextTypes]);

  const EverythingItem = includeContext(ChatContextType.Global) && (
    <StyledDropdownMenuItem onClick={handleEverythingSelected}>
      Everything
    </StyledDropdownMenuItem>
  );

  const DocumentItem = includeContext(ChatContextType.Document) && (
    <StyledDropdownMenuItem onClick={updatePendingContextType(ChatContextType.Document)}>
      Document
    </StyledDropdownMenuItem>
  );

  const FolderItem = includeContext(ChatContextType.Folder) && (
    <StyledDropdownMenuItem onClick={updatePendingContextType(ChatContextType.Folder)}>
      Folder
    </StyledDropdownMenuItem>
  );

  const KolProfileItem = includeContext(ChatContextType.KolProfile) && (
    <StyledDropdownMenuItem onClick={updatePendingContextType(ChatContextType.KolProfile)}>
      Profile
    </StyledDropdownMenuItem>
  );

  const label = getContextDisplayText(displayContextType);

  return {
    label: () => <>{label}</>,
    detail: null,
    menuItems: () => (
      <>
        {EverythingItem}
        {/* {FolderItem} */}
        {DocumentItem}
        {KolProfileItem}
      </>
    ),
  };
};

function getContextDisplayText(contextType: ChatContextType): string {
  switch (contextType) {
    case ChatContextType.Document:
      return 'Document';
    case ChatContextType.Folder:
      return 'Folder';
    case ChatContextType.Transcript:
      return 'Transcript';
    case ChatContextType.Global:
      return 'Everything';
    case ChatContextType.KolProfile:
      return 'Profile';
    default:
      throw new UnreachableCaseError(contextType);
  }
}

const StyledDropdownMenuItem = styled(DropdownMenuItem)({
  textAlign: 'left',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
  width: ContextSelectorStyles.width,
});